import React from 'react';
import styles from './index.module.scss';

const StepHeader = (props) => {

	return (
        <div>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.infoStep}>
                    Paso {props.step}/3
                </div>
            </div>
            <div className={styles.formularioContainer}>
                <div className={styles.cardTitle}>
                    <div className={styles.title}>{props.titleCard}</div>
                    <div className={styles.titledivisor}></div>
                    {props.children}
                </div>
            </div>
        </div>
	);
};

export default StepHeader;