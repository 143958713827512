import React from 'react';
import './GeneralModal.css'
import Modal from '../Modal/Modal';
import close from '../../../Assets/images/closeModal.svg'

const GeneralModal = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <div className='modal'>
                <div className="close">
                    <img src={close} onClick={props.onClose}/>
                </div>
                <div className='modalContainer'>
                    {(props.img) ? 
                        <div className='modalimgContainer'>
                            <img src={props.img} alt='success'/>
                        </div> : ''}
                    <div>
                        <p className='modaltitle'>{props.title}</p>
                        <p className='modalsubtitle'>{props.subtitle}</p>
                    </div>
                    <div className='modalbuttonContainer'>
                        <div className={`modalcontinuar ${props.type}`} onClick={props.exitModal}>{props.buttonDialog}</div>
                    </div>
                </div>
            </div>
        </Modal>
       
    )
}

export default GeneralModal;