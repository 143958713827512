import React, { Component } from 'react';
import styles from './OurServices.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import ServiceCard from '../../../Components/Abstracts/Cards/OurServicesCard/_serviceCard';
import service1 from '../../../Assets/images/servicios2.jpg';
import service2 from '../../../Assets/images/servicios1.jpg';
import service3 from '../../../Assets/images/servicios3.jpg';

export default class OurServices extends Component {
	state = {
		scaleUpBox: 2
	};

	setActive = (value) => {
		this.setState({
			scaleUpBox: value
		});
	};
	render() {

		return (
			<section className={styles.section} ref={this.props.ourServicesRef}>
				<div className={styles.container}>
					<div className={`${main.u__margin_top_medium_1} ${main.u__after_line_1}`}>
						<h2 className={main.text__secondary_heading_1}>Nuestros Servicios</h2>
						<h3 className={`${main.text__subtitle} ${main.u__margin_top_small_1}`}>
							Disfrutá de las ventajas de nuestra plataforma.
						</h3>
					</div>
				</div>
				<div className={styles.container}>
					<div className={`${styles.container__cards} ${main.u__margin_top_medium_1}`}>
						<ServiceCard
							subtitle='cambio digital'
							title='Vender moneda extranjera'
							text='Publicamos los precios de los principales bancos para que COMPARES, ELIJAS Y VENDAS cualquier moneda extranjera al mejor precio siempre'
							btnText='Vender'
							backgroundImage={service1}
							setActive={this.setActive}
							value={1}
							activeValue={this.state.scaleUpBox}
						/>
						<ServiceCard
							subtitle='cambio digital'
							title='Comprar moneda extranjera'
							text='Más de 50 monedas de todo el mundo, al mejor precio, sin moverte,simplemente desde tu Smartphone.'
							btnText='Comprar'
							backgroundImage={service2}
							setActive={this.setActive}
							value={2}
							activeValue={this.state.scaleUpBox}
						/>
						<ServiceCard
							subtitle='cambio digital'
							title='Medios electrónicos'
							text='Operá con nosotros de manera rápida y segura. Con tu tarjeta de Débito, desde tu cuenta bancaria o sentado en tu casa, negocio u oficina, elegí la modalidad de pago o liquidación que mas te convenga.'
							btnText='Más Info'
							backgroundImage={service3}
							setActive={this.setActive}
							value={3}
							activeValue={this.state.scaleUpBox}
						/>
					</div>
				</div>
			</section>
		);
	}
}
