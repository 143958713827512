import React, {useState, useEffect, useRef} from 'react';
import styles from './Perfil.module.scss';
import ovalSvg from '../../Assets/images/oval.svg';
import ProfileForm from '../../Components/Forms/ProfileForm';
import AlertasTable from '../../Components/AlertasTable';
import TransaccionesTable from '../../Components/TransaccionesTable';
import LoaderText from '../../Helpers/LoaderText';
import {IsLogedIn, GetHook, PostHook} from '../../Helpers/Services';
import Cookies from 'js-cookie';
import LoaderSpinner from '../../Helpers/LoaderSpinner';

const Perfil = () => {

	const [user, setUser] = useState({});
	const [Isloading, setIsLoading] = useState(false);
	const [spinnerLoading, setSpinnerloading] = useState(false);
	const [activeTab, setActiveTab] = useState('perfil');
    const [isEditable, setIsEditable] = useState('disabled');

	const fileUploader = useRef();

	useEffect(() => {
		if(IsLogedIn()) {
			setSpinnerloading(true);
		  GetHook('https://api.cambioposadas.com.ar/public/user/mine', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
		  .then(data => {
			  setUser(data);
			  setSpinnerloading(false);
		  });
		} else {
			document.location.href='/';
		}
	},[]);

	const onClickTabItem = (e) => {
		setActiveTab(e.target.id);
	}

	const onEditInputs = () => {
		if(isEditable === 'disabled') {
			setIsEditable('');
		} else {
			let formData = new FormData();
			formData.append('userType', user.userType);
			formData.append('sujetoObligado', user.sujetoObligado);
			formData.append('province', user.province);
			formData.append('postalCode', user.postalCode);
			formData.append('pep', user.pep);
			formData.append('name', user.name);
			formData.append('lastName', user.lastName);
			formData.append('dniFront', user.imageDniFront);
			formData.append('dniBack', user.imageDniBack);
			formData.append('civilStatus', user.civilStatus);
			formData.append('city', user.city);
			formData.append('address', user.address);
			formData.append('activityType', user.activityType);

			setIsLoading(true);
			PostHook(
				'https://api.cambioposadas.com.ar/public/user/editWithoutDates',
				formData,
				{'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
			.then(() => {
				setIsLoading(false);
			});
		}
	}

	const loadingText = (
		<div className={styles.saveStatus}>
			<p>Guardando...</p>
		</div>
	)

	const handleChangeUser = (e) => {
		user[e.name] = e.value;
		setUser(user);
	}

	const handleUpdateUser = (e) => {
		let formData = new FormData();
        formData.append('name', user.name);
        formData.append('lastName', user.lastName);
		formData.append(e.name, e.files[0]);
		setIsLoading(true);
        PostHook(
            'https://api.cambioposadas.com.ar/public/user/editWithoutDates',
            formData,
            {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')}
        ).then(() => {
            return  GetHook('https://api.cambioposadas.com.ar/public/user/mine', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
			.then(data => {
				setUser(data);
				setIsLoading(false);
			});
        });
	}

	const handleClickProfileImage = () => {
		fileUploader.current.click();
	}

	return (
		<section>
			<div className={styles.container}>
				<div className={styles.userDataBox}>
					{ (spinnerLoading) ? <LoaderSpinner/> : (
						<div>
							<div className={styles.loginData}>
								<div className={styles.imgbox}>
									<div className={styles.onlyImgStyles}>
										<img src={user.imageProfile} alt='user' className={styles.profileImg}/>
									</div>
									<div className={styles.editoval}>
										<div onClick={handleClickProfileImage}>
											<img src={ovalSvg} alt='edit' className={styles.editIcon} />
											<input type="file" id='profileFile' name='imageProfile' hidden ref={fileUploader} onChange={(e)=>handleUpdateUser(e.target)}/>
										</div>
									</div>
								</div>
								<h1>{user.fullName}</h1>
								<p>{user.username}</p>
							</div>
							<div className={styles.otherData}>
								<div className={styles.otherDataBox}>
									<p className={styles.title}>Fecha de Nacimiento</p>
									<p className={styles.data}>{user.birthDate}</p>
								</div>
								<div className={styles.otherDataBox}>
									<p className={styles.title}>Nacionalidad</p>
									<p className={styles.data}>{user.birthPlace}</p>
								</div>
								<div className={styles.otherDataBox}>
									<p className={styles.title}>Documento</p>
									<p className={styles.data}>{user.dni}</p>
								</div>
								<div className={styles.otherDataBox}>
									<p className={styles.title}>CUIT/CUIL</p>
									<p className={styles.data}>{user.cuilCuitCdi}</p>
								</div>
								<div className={styles.otherDataBox}>
									<p className={styles.title}>Telefono</p>
									<p className={styles.data}>{user.phoneNumber}</p>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className={styles.formBox}>
					<div>
						<ul className={styles.tabs}>
							<li>
								<p onClick={onClickTabItem} id="perfil" className={(activeTab === 'perfil') ? styles.active : ''}>Perfil</p>
							</li>
							<li>
								<p onClick={onClickTabItem} id="alertas" className={(activeTab === 'alertas') ? styles.active : ''}>Alertas</p>
							</li>
							<li>
								<p onClick={onClickTabItem} id="transacciones" className={(activeTab === 'transacciones') ? styles.active : ''}>Transacciones</p>
							</li>
							<LoaderText loading={Isloading} text={loadingText}/>
						</ul>
					</div>
					<div>
						{(() => {
							switch (activeTab) {
								case 'perfil':
									return (
									<ProfileForm
										isEditable={isEditable}
										onEditInputs={onEditInputs}
										user={user}
										handleChangeUser={handleChangeUser}
										handleUpdateUser={handleUpdateUser}
										spinnerLoading={spinnerLoading}
									/>);
								case 'alertas':
									return <AlertasTable/>;
								case 'transacciones':
									return <TransaccionesTable/>;
								default:
									return <ProfileForm/>;
							}
						})()}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Perfil;
