import React from 'react'
import styles from './AlertCard.module.scss'
import main from '../../../Abstracts/Main.module.scss'
import close from '../../../../Assets/images/close-times.svg'
import {DeleteHook, GetHook} from '../../../../Helpers/Services';
import Cookies from 'js-cookie';


const AlertCard = (props) => {

    const DeleteAlert = () => {
        return DeleteHook(`https://api.cambioposadas.com.ar/public/alert?alertId=${props.alertId}`,
        {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
        .then(() => {
            props.setDeleteModal(true);
            return GetHook('https://api.cambioposadas.com.ar/public/alert', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
            .then((data) => {
                props.handleAlertChanges(data);
            });
        });
    }

    return (
        <div className={`${styles.card} ${main.u__border_radius_1}`}>
            <div className={styles.card__flagBox}>
                <img className={styles.card__flag} src={props.flag} alt="flag-logo"/>
                <p className={styles.card__flagName}>{props.name}</p>
            </div>
            <div className={styles.alerttext}>
                <p>Quiero recibir alertas cuando el {props.currencyName} supere los $ {props.price} {`${(props.typeAlert === 'buy') ? 'para la compra' : 'para la venta'}`}</p>
            </div>
            <div className={styles.alertclose} onClick={DeleteAlert}>
                <img src={close} alt='close' />
            </div>
        </div>
    )
}

export default AlertCard;