import React from 'react';
import main from '../../Abstracts/Main.module.scss';
import styles from './FirstLogForm.module.scss';

const ButtonComponent = (props) => {
	return (
		<button
			type='button'
			onClick={() => props.toggleClass()}
            className={`${main.btn__blue} ${main.u__margin_top_small_1} 
            ${styles.form__active_button}`}
		>
			{props.text}
		</button>
	);
};
export default ButtonComponent;
