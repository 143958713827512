import React from 'react'
import styles from './UsCard.module.scss'
import main from '../../Main.module.scss'

export const UsCardBig = (props) => {
    return (
        <div className={`${styles.card__container}  ${styles.card__container__big}`}>
            <img src={props.image} alt="card Image"/>
        </div>
    )
}

export default UsCardBig;