import React from 'react';
import styles from './Operacion.module.scss';

const Operacion = (props) => {

	return (
        <div>
            <h1>Tu Compra</h1>
            <div className={styles.titledivisor}></div>
            <div className={styles.operacionBox}>
                <div className={styles.compraVenta}>
                    <div>
                        <p className={styles.compraVentaText}>Cuánto enviás</p>
                        <p className={styles.compraVentaNumber}>{props.operacion.envias}</p>
                    </div>
                    <div>
                        <div>
                            <img src={`https://resources.cambioposadas.com.ar/flags/${props.operacion.monedaCompra}.png`} alt={props.operacion.monedaCompra}/>
                        </div>
                        <div className={styles.compraVentaCoin}>{props.operacion.monedaCompra}</div>
                    </div>
                </div>
                <div className={styles.cambiodivisor}/>
                <div className={styles.compraVenta}>
                    <div>
                        <p className={styles.compraVentaText}>Cuánto recibís</p>
                        <p className={styles.compraVentaNumber}>{props.operacion.recibis}</p>
                    </div>
                    <div>
                        <div>
                            <img src={`https://resources.cambioposadas.com.ar/flags/${props.operacion.monedaVenta}.png`} alt={props.operacion.monedaVenta}/>
                        </div>
                        <div className={styles.compraVentaCoin}>{props.operacion.monedaVenta}</div>
                    </div>
                </div>
            </div>
        </div>
	);
};

export default Operacion;
