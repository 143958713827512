import React, {useState, useEffect} from 'react';
import Griddle, {RowDefinition, ColumnDefinition} from 'griddle-react';
import styles from './TransaccionesTable.module.scss';
import {IsLogedIn, GetHook} from '../../Helpers/Services';
import Cookies from 'js-cookie';

const TransaccionesTable = () => {


    const [data, setData] = useState([]);

    useEffect(() => {
        if(IsLogedIn()) {
            GetHook('https://api.cambioposadas.com.ar/public/transaction?page=0&sort=id,desc', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
            .then((data) => {
                setData(data);
            });
        }
    },[]);

    const LocalPlugin = require('griddle-react').plugins.LocalPlugin;

    const styleConfig = {
        icons: {
          TableHeadingCell: {
            sortDescendingIcon: <small>▼</small>,
            sortAscendingIcon: <small>▲</small>
          },
        },
        classNames: {
          Row: 'row-class',
        },
        styles: {
            Cell: {
                paddingTop:'1rem',
                paddingBottom:'1rem',
                color: '#000000',
                fontFamily: 'Quicksand',
                fontSize: '1.4rem',
                fontWeight: '500',
                lineHeight: '1.7rem',
                textAlign: 'center',

            },
            Filter: {
                display: "none"
            },
            SettingsToggle: {
                display: "none"
            },
            Table: {
                paddingTop:'30px',
                paddingLeft:'50px',
                paddingRight:'50px',
                width:'91rem'
            },
            TableHeading: {
                backgroundColor: '#4A90E2'
            },
            TableHeadingCell: {
                height:'39px',
                color: '#FFFFFF',
                fontFamily: 'Quicksand',
                fontSize: '1.4rem',
                fontWeight: '500',
                lineHeight: '1.7rem',
                textAlign: 'center'
            }
        }
    }

    const PreviousButton = ({onClick, hasPrevious, className, text, style}) => {
        return (
            <div content={text} icon='angle left' disabled={!hasPrevious} labelposition='left' floated='left' onClick={onClick} style={style} className={className}></div>
        )
    }

    const NextButton = ({onClick, hasNext, className, text, style}) => {
        return (
            <div content={text} icon='angle right' disabled={!hasNext} labelposition='right' floated='right' onClick={onClick} style={style} className={className}></div>
        )
    }

    const CustomPageDropdown = ({maxPages, currentPage, setPage}) => {
        let pagesObj = [];
        for (let i = 1; i <= maxPages; i++) {
            pagesObj.push({ value: i });
        }
        return (
            <div className={`${styles.paginationBox} page-count-wrapper`}>
                {pagesObj.map((data, key)=> {
                    return (
                        <div key={key} className={(currentPage === data.value) ? styles.pageNumberContainerSelected : styles.pageNumberContainer} value={data.value} onClick={(event) => setPage(parseInt(event.target.getAttribute('value')))}>
                            <div className={`${(currentPage === data.value) ? styles.numberSelected : ''} styles.number`}  value={data.value} onClick={(event) => setPage(parseInt(event.target.getAttribute('value')))}>{data.value}</div>
                        </div>
                    );
                })}
            </div>
        )
    }

    const PaginationPlugin = {
        components: {
          NextButton,
          PreviousButton,
          PageDropdown: CustomPageDropdown,
        },
        styleConfig: {
          classNames: {
            Pagination: 'center',
          },
          styles: {
            Cell: { textAlign: 'center' },
          }
        },
      };

    return (
        <div>
            <Griddle
                data={data.content}
                styleConfig={styleConfig}
                plugins={[LocalPlugin, PaginationPlugin]}
                pageProperties={{
                    currentPage: 1,
                    pageSize: 10,
                    recordCount: data.length,
                }}
            >
                <RowDefinition>
                    <ColumnDefinition id="id" title="Operacion" order={1}/>
                    <ColumnDefinition id="purchasedTotalPrice" title="Recibido" order={2}/>
                    <ColumnDefinition id="from" title="Moneda Compra" order={3}/>
                    <ColumnDefinition id="status" title="Estado" order={4}/>
                    <ColumnDefinition id="amount" title="Total Entregado" order={5}/>
                    <ColumnDefinition id="to" title="Moneda Venta" order={6}/>
                    <ColumnDefinition id="pickUpDate" title="Fecha" order={7}/>
                </RowDefinition>
            </Griddle>
        </div>

    );
}

export default TransaccionesTable;