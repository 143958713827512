import React from 'react';

const LoaderText = (props) => {
    if(props.loading) {
        return (
            <div>
                {props.text}
            </div>
        );
    } else {
        return (
            <div>
                {props.children}
            </div>
        );
    }
}

export default LoaderText;