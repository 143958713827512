import React, {useEffect} from 'react';
import styles from './FormularioEnvio.module.scss';
import transferencia from '../../../Assets/images/transferencia.svg';
import transferenciaWhite from '../../../Assets/images/transferencia-blanco.svg';
import quintana from '../../../Assets/images/quintana.svg';
import quintanaWhite from '../../../Assets/images/quintana-blanco.svg';
import central from '../../../Assets/images/central.svg';
import centralWhite from '../../../Assets/images/central-blanco.svg';
import StepHeader from '../FormularioHeader';
import FormularioCard from '../FormularioCard';

const FormularioEnvio = (props) => {

    useEffect(() => {
        props.isCompleted(false);
        if(props.selectedDeliveryMethod && props.selectedPayMethod) {
            props.isCompleted(true);
        }
    }, [props.selectedDeliveryMethod, props.selectedPayMethod])

    return (
        <StepHeader title={props.title} step={props.step}>
            <FormularioCard titleCard={props.titleCard}>
                <div className={styles.optionsContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="central" onChange={(e) => props.handleSelectedPayMethod(e.target.value)}>Delivery-Casa Central
                            <input type="radio" id="central" name="delivery" value="central" defaultChecked={props.selectedPayMethod === 'central'} />
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedPayMethod === 'central') ? styles.itemDescriptionSelectedPay : styles.itemDescriptionPay}>
                            <img src={(props.selectedPayMethod === 'central') ? centralWhite : central} alt='central'/>
                            <p className={styles.text}>
                            Sucursal: Casa Central <br/>
                            Dirección: Manuel Quintana 488, CABA <br/>
                            CP: 1129 <br/>
                            Horario: Lu-Vi De 9:00 a 19:00 <br/>
                            Teléfono: 0800-220-2262 <br/>
                            </p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="quintana" onChange={(e) => props.handleSelectedPayMethod(e.target.value)}>Delivery-Suc. Quintana
                            <input type="radio" id="quintana" name="delivery" value="quintana" defaultChecked={props.selectedPayMethod === 'quintana'}/>
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedPayMethod === 'quintana') ? styles.itemDescriptionSelectedPay : styles.itemDescriptionPay}>
                            <img src={(props.selectedPayMethod === 'quintana') ? quintanaWhite : quintana} alt='quintana'/>
                            <p className={styles.text}>
                            Sucursal: Quintana <br/>
                            Dirección: Manuel Quintana 488, CABA <br/>
                            CP: 1129 <br/>
                            Horario: Lu-Vi De 9:00 a 19:00 <br/>
                            Teléfono: 0800-220-2262 <br/>
                            </p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="enviotransferencia" onChange={(e) => props.handleSelectedPayMethod(e.target.value)}>Transferencia
                            <input type="radio" id="enviotransferencia" name="delivery" value="enviotransferencia" defaultChecked={props.selectedPayMethod === 'enviotransferencia'}/>
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedPayMethod === 'enviotransferencia') ? styles.itemDescriptionSelectedPay : styles.itemDescriptionPay}>
                            <img src={(props.selectedPayMethod === 'enviotransferencia') ? transferenciaWhite : transferencia} alt='enviotransferencia'/>
                            <p className={styles.text}>
                            Banco Industrial<br/>
                            Cuenta Corriente en Dolares: 1-640300/2<br/>
                            CBU: 322000181200640300002<br/>
                            Alias: CAMBIO.POSADAS.DOLAR<br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.disclaimer}>
                    <p>*Éstos son los datos que necesitas para realizar el pago. Al confirmar la transacción, te enviaremos un mail con los mismos para que puedas volver a verlos en caso de necesitarlos.</p>
                </div>
            </FormularioCard>
            <FormularioCard titleCard={props.segundaTitleCard}>
                <div className={styles.optionsContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="sucursal" onChange={(e) => props.handleSelectedDeliveryMethod(e.target.value)}>Sucursal
                            <input type="radio" id="sucursal" name="recepcion" value="sucursal" defaultChecked={props.selectedDeliveryMethod === 'sucursal'}/>
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedDeliveryMethod === 'sucursal') ? styles.itemDescriptionSelectedDelivery : styles.itemDescriptionDelivery}>
                            <img src={(props.selectedDeliveryMethod === 'sucursal') ? centralWhite : central} alt='sucursal'/>
                            <p className={styles.text}>
                                Retiro del dinero en persona por sucursal
                            </p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="delivery" onChange={(e) => props.handleSelectedDeliveryMethod(e.target.value)}>Delivery
                            <input type="radio" id="delivery" name="recepcion" value="delivery" defaultChecked={props.selectedDeliveryMethod === 'delivery'}/>
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedDeliveryMethod === 'delivery') ? styles.itemDescriptionSelectedDelivery : styles.itemDescriptionDelivery}>
                            <img src={(props.selectedDeliveryMethod === 'delivery') ? quintanaWhite : quintana} alt='delivery'/>
                            <p className={styles.text}>
                                Recepción del dinero a través de delivery
                            </p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="transferencia" onChange={(e) => props.handleSelectedDeliveryMethod(e.target.value)}>Transferencia
                            <input type="radio" id="transferencia" name="recepcion" value="transferencia" defaultChecked={props.selectedDeliveryMethod === 'transferencia'}/>
                            <span className={styles.checkmark}></span>
                        </label>
                        <div className={(props.selectedDeliveryMethod === 'transferencia') ? styles.itemDescriptionSelectedDelivery : styles.itemDescriptionDelivery}>
                            <img src={(props.selectedDeliveryMethod === 'transferencia') ? transferenciaWhite : transferencia} alt='transferencia'/>
                            <p className={styles.text}>
                                Recepción del dinero por transferencia
                            </p>
                        </div>
                    </div>
                </div>
            </FormularioCard>
        </StepHeader>
	);
};

export default FormularioEnvio;