import React, {useState, useEffect} from 'react';
import './ProfileForm.css';
import {Provincias, Residencia, EstadoCivil, TipoDeActividad} from '../../../Helpers/GlobalVariables';

import TextInput from '../TextInput';
import UploadIdInput from '../UploadIdInput';
import SelectInput from '../SelectInput';

import LoaderSpinner from '../../../Helpers/LoaderSpinner';

const ProfileForm = (props) => {
    const [isclickedFrontId, setClickedFrontId] = useState(false);
    const [isclickedBackId, setClickedBackId] = useState(false);
    const [name, setName] = useState('');


    const inputTextProps = [
        {label:'Localidad', name: 'city', placeholder: 'seleccione', value: props.user.city},
        {label:'Calle', name: 'address', placeholder: 'Dirección', value: props.user.address},
        {label:'Código Postal', name: 'postalCode', placeholder: 'Código Postal', value: props.user.postalCode}
    ];

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if(e.target.id === 'frente' || e.target.id === 'reverso') {
                return;
            } else {
                setClickedBackId(false);
                setClickedFrontId(false);
            }
        });
    }, []);

    const actionId = (e) => {
        switch (e.target.id) {
            case 'frente':
                setClickedBackId(false);
                setName('dniFront');
                return setClickedFrontId(!isclickedFrontId);
            case 'reverso':
                setClickedFrontId(false);
                setName('dniBack');
                return setClickedBackId(!isclickedBackId);
            default:
                return;
        }
    }

    let civilStatus;
    if (props.user.civilStatus === 'MARRIED') {
        civilStatus = 'Casad@';
    }

    return (
        <div className='formBox'>
            { (props.spinnerLoading) ? <LoaderSpinner/> : (
                <div>
                    <div className='inputSection'>
                        <SelectInput label={'Provincia'} name={'province'} selectOptions={Provincias} value={props.user.province} isEditable={props.isEditable} handleChange={props.handleChangeUser}/>
                        {inputTextProps.map((data, key)=> {
                            return <TextInput label={data.label} name={data.name} key={key} placeholder={data.placeholder} value={data.value} isEditable={props.isEditable} handleChange={props.handleChangeUser}/>
                        })}
                        <SelectInput label={'Residencia Legal'} name={'userType'} selectOptions={Residencia} value={props.user.userType} isEditable={props.isEditable} handleChange={props.handleChangeUser}/>
                        <SelectInput label={'Estado Civil'} name={'civilStatus'} selectOptions={EstadoCivil} value={civilStatus} isEditable={props.isEditable} handleChange={props.handleChangeUser}/>
                        <SelectInput label={'Tipo de Actividad'} name={'activityType'} selectOptions={TipoDeActividad} value={props.user.activityType} isEditable={props.isEditable} handleChange={props.handleChangeUser}/>
                        <div className='inputWrapper'>
                            <div className='inputCheckBox'>
                                <label className='containercheckbox'>PEP
                                    <input type="checkbox" name={'pep'} defaultChecked={props.user.pep} onChange={e => {
                                        props.handleChangeUser(e.target)
                                    }}/>
                                    <span className='checkmark'></span>
                                </label>
                                <label className='containercheckbox'>Sujeto Obligado
                                    <input type="checkbox" name={'sujetoObligado'} defaultChecked={props.user.sujetoObligado} onChange={e => {
                                        props.handleChangeUser(e.target)
                                    }}/>
                                    <span className='checkmark'></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='inputSection'>
                        <div className='inputUpload'>
                            <div className='frontId'>
                                <img src={props.user.imageDniFront} alt="frente" onClick={actionId} id="frente"/>
                            </div>
                            <UploadIdInput isAvailable={isclickedFrontId} name={name} handleUpdateUser={props.handleUpdateUser}/>
                        </div>
                        <div className='inputUpload'>
                            <div className='backId'>
                                <img src={props.user.imageDniBack} alt="reverso" onClick={actionId} id="reverso"/>
                            </div>
                            <UploadIdInput isAvailable={isclickedBackId} name={name} handleUpdateUser={props.handleUpdateUser}/>
                        </div>
                    </div>

                    <div className='buttonSection'>
                        <button className='button' onClick={props.onEditInputs}>{(props.isEditable === 'disabled') ? 'Editar': 'Guardar'}</button>
                    </div>
                </div>
            )}
        </div>

    );
}

export default ProfileForm;