import React, {useEffect} from 'react';
import styles from './Transferencia.module.scss';
import StepHeader from '../FormularioHeader';
import FormularioCard from '../FormularioCard';

const Transferencia = (props) => {

    useEffect(() => {
        props.isCompleted(false);
        if(props.razonSocial && props.CBU && props.CUIT && props.alias) {
            props.isCompleted(true);
        }
    }, [props.razonSocial, props.CBU, props.CUIT, props.alias])

	return (
        <StepHeader title={props.title} step={props.step}>
            <FormularioCard titleCard={props.titleCard}>
                <div className={styles.inputContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="razonSocial" onChange={(e) => props.handleCompletedRazonSocial(e.target.value)}>Razon Social*
                            <input type="text" id="razonSocial" name="razonSocial" value={props.razonSocial}/>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="CBU" onChange={(e) => props.handleCompletedCBU(e.target.value)}>CBU*
                            <input type="text" id="CBU" name="CBU" value={props.CBU}/>
                        </label>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="CUIT" onChange={(e) => props.handleCompletedCUIT(e.target.value)}>CUIT*
                            <input type="text" id="CUIT" name="CUIT" value={props.CUIT}/>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="alias" onChange={(e) => props.handleCompletedAlias(e.target.value)}>Alias*
                            <input type="text" id="alias" name="alias" value={props.alias} />
                        </label>
                    </div>
                </div>
                <div className={styles.disclaimer}>
                    <p>*Utilizaremos éstos datos para realizar el envío del dínero a donde nos digas. Recordá que deberás estar presente en el lugar que indiques a la hora de recibirlo.</p>
                </div>
            </FormularioCard>
        </StepHeader>
	);
};

export default Transferencia;