import React from 'react';
import styles from './Register.module.scss';
import Residencia from '../../../Forms/RegisterForm/Residencia';

const Register = () => {

    return (
        <div className={styles.registerContainer}>
           <Residencia/>
        </div>
    )
}

export default Register;