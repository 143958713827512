import React, {useState} from 'react';
import FormularioCompra from './step1/FormularioCompra';
import FormularioEnvio from './step2/FormularioEnvio';
import Envio from './step3/Envio';
import Transferencia from './step4/Transferencia';
import Resumen from './step5/Resumen';
import styles from './ModuloCompraVenta.module.scss';

import moduloSeguro from '../../Assets/images/moduloseguro.png';

import OperationSuccess from '../Modals/OperationSuccess/OperationSuccess';

const ModuloCompraVenta = (props) => {

    const [cuantoEnvias, setCuantoEnvias] = useState(1);
    const [monedaCompra, setMonedaCompra] = useState(props.monedaCompra);
    const [monedaVenta, setMonedaVenta] = useState(props.monedaVenta);
    const [isOpen, setToggleModal] = useState(false);
    const [step, setStep] = useState(1);
    const [completed, setCompleted] = useState(false);
    const [selectedPayMethod, setSelectedPayMethod] = useState('');
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fecha, setFecha] = useState('');
    const [depto, setDepto] = useState('');
    const [horario, setHorario] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [CBU, setCBU] = useState('');
    const [CUIT, setCUIT] = useState('');
    const [alias, setAlias] = useState('');
    const [sujetoObligado, setSujetoObligado] = useState(false);
    const [PEP, setPEP] = useState(false);

    
    const handleStepForward = () => {
        setStep(step + 1);

        if(step === 2) {
            if(selectedDeliveryMethod === 'delivery') {
                setStep(3);
            } else if (selectedDeliveryMethod === 'transferencia') {
                setStep(4);
            } else {
                setStep(5);
            }
        }

        if(step === 3) {
            setStep(5);
        }

        if(step === 4) {
            setStep(5);
        }
    }

    const toggleModal = () => {
        setToggleModal(!isOpen);
    }

    const sendInfoToAPI = () => {
        setToggleModal(!isOpen);
    }

    const handleStepBackwards = () => {
        setStep(step - 1);
        if(step === 4 || step === 3) {
            if(selectedDeliveryMethod === 'delivery') {
                setStep(2);
            } else if (selectedDeliveryMethod === 'transferencia') {
                setStep(2);
            }
        }

        if(step === 5) {
            if(selectedDeliveryMethod === 'delivery') {
                setStep(3);
            } else if (selectedDeliveryMethod === 'transferencia') {
                setStep(4);
            } else {
                setStep(2)
            }
        }

    }

    const handleIsCompleted = (value) => {
        if(value) {
            setCompleted(value);
        } else {
            setCompleted(value);
        }
    }

	return (
        <div>
            {isOpen && <OperationSuccess onClose={toggleModal}/>}
            <div className={styles.progressBar}>
                {(() => {
                    switch (step) {
                        case 1:
                                return <div className={styles.stepone}></div>
                        case 2:
                                return <div className={styles.steptwo}></div>
                        case 3:
                                return <div className={styles.steptwo}></div>
                        case 4:
                                return <div className={styles.steptwo}></div>
                        case 5:
                                return <div className={styles.stepthree}></div>
                        default:
                                return ('');
                    }
                })()}

            </div>
            <div className={styles.stepContainer}>
                {(() => {
                    switch (step) {
                        case 1:
                            return (
                                <FormularioCompra
                                    isCompleted={handleIsCompleted}
                                    cuantoEnvias={cuantoEnvias}
                                    handleCuantoEnvias={(value)=> setCuantoEnvias(value)}
                                    monedaCompra={monedaCompra}
                                    handleMonedaCompra={(value)=> {
                                        setMonedaCompra(value)
                                    }}
                                    monedaVenta={monedaVenta}
                                    handleMonedaVenta={(value)=> {
                                        setMonedaVenta(value)
                                    }}
                                    cotizaciones={props.cotizaciones}
                                    title={'Compra/Venta'}
                                    step={step}
                                    titleCard={'Confirmá tu moneda y elegí tu monto'}
                                />
                            )
                        case 2:
                            return (
                                <FormularioEnvio
                                    isCompleted={handleIsCompleted}
                                    selectedDeliveryMethod = {selectedDeliveryMethod}
                                    handleSelectedDeliveryMethod={(value)=> setSelectedDeliveryMethod(value)}
                                    selectedPayMethod = {selectedPayMethod}
                                    handleSelectedPayMethod={(value)=> setSelectedPayMethod(value)}
                                    title={'¿Cómo querés enviar y recibir el dinero?'}
                                    step={step}
                                    titleCard={'Seleccioná un método de pago'}
                                    segundaTitleCard={'Seleccioná un método de recepción'}
                                />
                            )
                        case 3:
                            return (
                                <Envio
                                    isCompleted={handleIsCompleted}
                                    title={'¿Dónde querés recibir el dinero?'}
                                    step={2}
                                    titleCard={'Complete sus datos'}
                                    handleCompletedDireccion={(value)=> setDireccion(value)}
                                    direccion={direccion}
                                    handleCompletedFecha={(value)=> setFecha(value)}
                                    fecha={fecha}
                                    handleCompletedDepto={(value)=> setDepto(value)}
                                    depto={depto}
                                    handleCompletedHorario={(value)=> setHorario(value)}
                                    horario={horario}
                                />
                            )
                        case 4:
                            return (
                                <Transferencia
                                    isCompleted={handleIsCompleted}
                                    title={'¿Dónde querés recibir el dinero?'}
                                    step={2}
                                    titleCard={'Complete sus datos'}
                                    handleCompletedRazonSocial={(value)=> setRazonSocial(value)}
                                    razonSocial={razonSocial}
                                    handleCompletedCBU={(value)=> setCBU(value)}
                                    CBU={CBU}
                                    handleCompletedCUIT={(value)=> setCUIT(value)}
                                    CUIT={CUIT}
                                    handleCompletedAlias={(value)=> setAlias(value)}
                                    alias={alias}
                                />
                            )
                        case 5:
                            return (
                                <Resumen
                                    operacion={props.operacion}
                                    isCompleted={handleIsCompleted}
                                    title={'Resumen'}
                                    step={3}
                                    titleCard={'Información de la transacción'}
                                    selectedPayMethod={selectedPayMethod}
                                    selectedDeliveryMethod={selectedDeliveryMethod}
                                    handleCompletedSujetoObligado={(value)=> setSujetoObligado(value)}
                                    sujetoObligado={sujetoObligado}
                                    handleCompletedPEP={(value)=> setPEP(value)}
                                    PEP={PEP}
                                />
                            )
                        default:
                            return ('');
                    }
                })()}
                <div className={styles.buttonContainer}>
                    <button className={(step===1) ? styles.atrasDisabled : styles.atras} onClick={handleStepBackwards} disabled={(step===1)}>Atras</button>
                    {(step === 5) ? <button className={styles.confirmarOperacion} onClick={sendInfoToAPI} disabled={!completed}>Confirmar Operación</button> : <button id={(!completed)? styles.disable: ''} className={styles.continuar} onClick={handleStepForward} disabled={(!completed)}>Continuar</button>}

                </div>
                <div className={styles.moduloSeguro}>
                    <img src={moduloSeguro} alt='Modulo Seguro'/>
                </div>
            </div>
        </div>
	);
};

export default ModuloCompraVenta;
