import React from 'react';
import styles from './CompraVenta.module.scss';
import Operacion from '../../Components/Operacion/Operacion';
import Cotizaciones from '../../Components/Cotizaciones/Cotizaciones';
import ModuloCompraVenta from '../../Components/ModuloCompraVenta/ModuloCompraVenta';


const CompraVenta = () => {

    // REFACTOR ----> USER DATA IN ARRAY FORMAT
    let operacion = {
            "envias": 1,
            "recibis": 60.40,
            "monedaCompra": 'USD',
            "nombrecompletocompra": "Dolar Estadounidense",
            "monedaVenta": 'ARS',
            "nombrecompletoventa": "Peso Argentino",
        };

    let monedaCompra = {moneda: "GBP", name: "UK", compra: 47.93, venta: 51.84};

    let monedaVenta = {
        "moneda": 'ARS',
        "name": 'ARG',
        "compra": 59,
        "venta": 60.40
    };

    let cotizaciones = [
        {
            "moneda": 'USD',
            "name": 'USA',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'GBP',
            "name": 'UK',
            "compra": 47.93,
            "venta": 51.84
        },
        {
            "moneda": 'CHF',
            "name": 'SUI',
            "compra": 31.62,
            "venta": 40.18
        },
        {
            "moneda": 'INR',
            "name": 'IND',
            "compra": 0.41,
            "venta": 0.54
        },
        {
            "moneda": 'CLP',
            "name": 'CHI',
            "compra": 0.052,
            "venta": 0.062
        },
        {
            "moneda": 'CAD',
            "name": 'CAN',
            "compra": 25.38,
            "venta": 29.66
        },
        {
            "moneda": 'COP',
            "name": 'COL',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'PEN',
            "name": 'PER',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'TRY',
            "name": 'TUR',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'EUR',
            "name": 'EUR',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'JPY',
            "name": 'JAP',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'MXN',
            "name": 'MEX',
            "compra": 59,
            "venta": 60.40
        },
        {
            "moneda": 'ARS',
            "name": 'ARG',
            "compra": 59,
            "venta": 60.40
        }
    ]

	return (
		<section>
            <div className={styles.container}>
                <div className={styles.sidebar}>
                    <div className={styles.operacion}>
                        <Operacion operacion={operacion}/>
                    </div>
                    <div className={styles.cotizacionesContainer}>
                        <h1>Cotizaciones</h1>
                        <div className={styles.titledivisor}></div>
                        <div className={styles.cotizaciones}>
                            <Cotizaciones cotizaciones={cotizaciones}/>
                        </div>
                    </div>
                </div>
                <div className={styles.compraventa}>
                    <div>
                        <ModuloCompraVenta
                            operacion={operacion}
                            cotizaciones={cotizaciones}
                            monedaCompra={monedaCompra}
                            monedaVenta={monedaVenta}
                        />
                    </div>
                </div>
			</div>
		</section>
	);
};

export default CompraVenta;
