import React, {Component} from 'react'
import styles from './Map.module.scss'
import main from '../../../Components/Abstracts/Main.module.scss'

export default class Map extends Component {

    state = {
        activeMap: 1
    }

    setActiveMap = (value) => {
        this.setState({
            activeMap: value
        })
       
    }

    render() {

        const { activeMap } = this.state;

        return (
            <section className={`${styles.section} ${main.u__margin_top_medium_1}`}>

           
                <form action="#" className={styles.form}>
                    <div className={`${styles.form__container} ${main.u__border_radius_1}`}>
                        <div className={`${styles.form__button_container}`}>
                            <input type="radio" className={`${styles.form__input} ${activeMap === 1 ? styles.form__input_checked : ''}`} id='Central' name='Sucursal' onClick={()=>this.setActiveMap(1)} />
                            <label className={main.text__secondary_heading_3} htmlFor='Central'>
                                <span className={`${styles.form__radio_button} ${styles.form__radio_button_1}`}></span>
                                Casa Central
                            </label>
                            <p className={`${main.text__paragraph_2} ${main.u__margin_top_tiny_1}`}>Dirección: ADB, Posadas 1562, C1112 CABA</p>
                            <p className={main.text__paragraph_2}>Teléfono: 0800-220-CAMBIO (226246)</p>
                            <p className={main.text__paragraph_2}>Mail: info@cambioposadas.com.ar</p>
                        </div>
                        <div className={`${styles.form__button_container}`}>
                            <input type="radio" className={`${styles.form__input} ${activeMap === 2 ? styles.form__input_checked : ''}`} id='Quintana' name='Sucursal' onClick={()=>this.setActiveMap(2)}/>
                            <label className={main.text__secondary_heading_3} htmlFor='Quintana'>
                                <span className={`${styles.form__radio_button} ${styles.form__radio_button_2}`}></span>
                                Sucursal Quintana
                            </label>
                            <p className={`${main.text__paragraph_2} ${main.u__margin_top_tiny_1}`}>Dirección: Av. Pres. Manuel Quintana 488, C1129 CABA</p>
                            <p className={main.text__paragraph_2}>Teléfono: (11) 4805-5626</p>
                            <p className={main.text__paragraph_2}>Mail: info@cambioposadas.com.ar</p>
                        </div>
                    </div>
                </form>
                

                <iframe  className={`${styles.map} ${activeMap === 1 ? styles.map__active : ''}`} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3100.3051877418866!2d-58.39010845223712!3d-34.58749692709124!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x33097eb5425e6022!2sCAMBIO+POSADAS!5e0!3m2!1ses-419!2sar!4v1531333446901" frameBorder="0"></iframe>

                <iframe value={2} className={`${styles.map} ${activeMap === 2 ? styles.map__active : ''}`} src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3284.5968300738978!2d-58.388806389772604!3d-34.58906666704794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAv.+Pres.+Manuel+Quintana+488%2C+C1129!5e0!3m2!1ses!2sar!4v1547836316829" frameBorder="0"></iframe>
                </section>
        )
    }
}
