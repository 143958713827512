import styles from './Header.module.scss';
import main from '../Abstracts/Main.module.scss';
import {IsLogedIn, GetHook} from '../../Helpers/Services';
import Cookies from 'js-cookie';
import Sign from '../../Components/Modals/Sign/Sign';
import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';

import userIcon from '../../Assets/images/usericon.svg';
import userChevronDown from '../../Assets/images/userchevrondown.svg';

import LoaderText from '../../Helpers/LoaderText';
import logoutImg from '../../Assets/images/retira-2.svg';
import GeneralModal from '../Modals/GeneralModal/GeneralModal';

const Header = (props) => {

	const [Isloading, setIsLoading] = useState(false);
	const [user, setUser] = useState({});
	const [loginModal, setLoginModal] = useState(false);
	const [logoutModal, setLogoutModal] = useState(false);
	const [showUserMenu, setUserMenu] = useState(false);

	useEffect(() => {
	  if(IsLogedIn()) {
		setIsLoading(true);
		GetHook('https://api.cambioposadas.com.ar/public/user/mine', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
		.then(data => {
			setUser(data);
			setIsLoading(false);
		});
	  }
	},[]);

	const handleUser = (data) => {
		setUser(data);
	}

	const handleLoading = (data) => {
		setIsLoading(data)
	}

	const toggleModal = () => {
		setLoginModal(false);
	}

	const handleProfileMenu = () => {
		setUserMenu(!showUserMenu);
	}

	const handleLogout = () => {
		setLogoutModal(true)
	}

	const isLogout = () => {
		Cookies.remove('access-Token-Cambio-Posadas');
		document.location.href='/';
	}

	const loadingText = (
		<div className={styles.nav__profileLogged} onClick={handleProfileMenu}>
			<span><img src={userIcon} alt='user-icon'/></span>
			<span>Cargando</span>
			<span className={styles.nav__profileLogOut}>
				<img src={userChevronDown} alt='chevron-down'/>
				{ showUserMenu && 
					<div className={styles.nav__submenuLogged}>
						<Link className={styles.nav__logged} to="/perfil">Perfil</Link>
						<div className={styles.nav__logged} onClick={handleLogout}>Cerrar Sesión</div>
					</div>
				}
			</span>
		</div>
	);

	return (
		<section className={`${styles.section} ${styles.section__mobile1}`} ref={props.homeRef}>
			{(loginModal && <Sign onClose={toggleModal}  handleUser={handleUser} handleLoading={handleLoading}/>)}
			{logoutModal && 
				<GeneralModal
					onClose={()=>setLogoutModal(false)}
					img={logoutImg}
					title={'Cerrar Sesión'}
					subtitle={'¿Estás seguro que deseas cerrar sesión?'}
					buttonDialog={'Cerrar Sesión'}
					exitModal={isLogout}
					type='warning'

				/>
			}
			<nav className={styles.nav}>
				<div className={styles.nav__container}>
					<img
						className={styles.nav__flag}
						src='https://resources.cambioposadas.com.ar/flags/USD.png'
						alt='dolar__flag'
					/>
					<div className={styles.nav__buy}> Compra $ 59</div>
					<div className={styles.nav__sell}>Venta $ 60.04</div>
				</div>
				<div className={styles.nav__container}>
					<div className={styles.nav__text}>
						Ubicación
					</div>
					<div className={styles.nav__text}>
						0800-220-CAMBIO
					</div>
					<LoaderText loading={Isloading} text={loadingText}>
					{!_.isEmpty(user) ? 
						<div className={styles.nav__profileLogged} onClick={handleProfileMenu}>
							<span><img src={userIcon} alt='user-icon'/></span>
							<span>{user.fullName}</span>
							<span className={styles.nav__profileLogOut}>
								<img src={userChevronDown} alt='chevron-down'/>
								{ showUserMenu && 
									<div className={styles.nav__submenuLogged}>
										<Link className={styles.nav__logged} to="/perfil">Perfil</Link>
										<div className={styles.nav__logged} onClick={handleLogout}>Cerrar Sesión</div>
									</div>
								}
							</span>
						</div> 
						: 
						<button onClick={()=> {setLoginModal(true)}} className={`${main.btn} ${main.btn__blue} ${main.btn__small}`}>Iniciar sesión</button>
					}
					</LoaderText>
				</div>
			</nav>
		</section>
	);
}

export default Header;
