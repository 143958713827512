import React from 'react'
import { Field, useField} from 'formik';
import main from '../../Abstracts/Main.module.scss'
import styles from './FirstLogForm.module.scss'
import validInput from '../../../Assets/images/modal-perfecto-b.svg'
import invalidInput from '../../../Assets/images/close.svg';

const FieldComponent = ({label, type, ...props}) => {

    
    const [field, meta] = useField(props);
    const errorText = meta.error ? meta.error : '';
    const valid = !meta.error && field.value !== '' ? true : false;

    return (
        <div className={styles.form__container_sub}>
            <label className={`${styles.form__label} ${main.u__margin_top_small_1}`} htmlFor={field.name}>{label}</label>
            <Field autoComplete="off" id={field.name} className={`${styles.form__input} ${main.u__margin_top_tiny_2} ${meta.error &&type !== 'date' ? styles.form__input_invalid_border :''} ${valid && type !=='date' ? styles.form__input_valid_border : ''}`} type={type} {...field} helperText={errorText} error={!!errorText}/>
            <div className={`${meta.error ? styles.form__input_invalid_text : ''}`}>{errorText}</div>
            <span >
                <img className={`${valid && type !== 'date' ? styles.form__input_valid_icon : ''} ${!props.label ? styles.form__input_valid_icon_without_label: ''} ${styles.form__icon} `} src={validInput} alt="valid icon"/>
            </span>
            <span className={`${meta.error ? styles.form__input_invalid_icon : ''} ${!props.label ? styles.form__input_valid_icon_without_label: ''} ${styles.form__icon}`}>
                <img src={invalidInput} alt="valid icon"/>
            </span>
        </div>
    )
}

export default FieldComponent;