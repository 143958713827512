import React, {useEffect, useState} from 'react';
import styles from './FormularioCompra.module.scss';
import Card from '../../Cotizaciones/Card/Card';
import StepHeader from '../FormularioHeader';
import FormularioCard from '../FormularioCard';
import Cotizaciones from './Cotizaciones/Cotizaciones';

import charetDown from '../../../Assets/images/charetdown.svg';

const FormularioCompra = (props) => {

    const [charetCompraOn, setCharetCompraOn] = useState(false);
    const [charetVentaOn, setCharetVentaOn] = useState(false);

    useEffect(() => {
        props.isCompleted(false);
        if(props.cuantoEnvias && props.monedaCompra && props.monedaVenta) {
            props.isCompleted(true);
        }
    }, [props.cuantoEnvias, props.monedaCompra, props.monedaVenta])

    const handleDropdown = (e) => {
        if(e.target.id === 'compra') {
            setCharetVentaOn(false);
            setCharetCompraOn(!charetCompraOn);
        }
        if(e.target.id === 'venta') {
            setCharetCompraOn(false);
            setCharetVentaOn(!charetVentaOn);
        }
    }

	return (
        <StepHeader title={props.title} step={props.step}>
            <FormularioCard titleCard={props.titleCard}>
                <div className={styles.cardContainer}>
                    <div>
                        <label className={styles.compraText} htmlFor="cuantoenvias">Cuánto enviás
                            <input className={styles.compraNumber} type="number" id="cuantoenvias" name="cuantoenvias" value={props.cuantoEnvias} onChange={(e) => props.handleCuantoEnvias(e.target.value)}/>
                        </label>
                    </div>
                    <div className={styles.cardCotizaciones}>
                        {(() => {
                            const data = props.cotizaciones.find(data => {
                                return props.monedaCompra.moneda === data.moneda
                            });
                            return <Card
                                flag={`https://resources.cambioposadas.com.ar/flags/${data.moneda}.png`}
                                name={data.name}
                                buyPrice={data.compra}
                                sellPrice={data.venta}
                            >
                                <img src={charetDown} alt='charet-down' id={'compra'} onClick={handleDropdown}/>
                            </Card>
                        })()}
                        <div className={(charetCompraOn) ? styles.dropdownContainer: styles.dropdownHide}>
                            <div className={styles.cardStyles}>
                                <Cotizaciones cotizaciones={props.cotizaciones} handleChange={(value)=> {
                                    setCharetCompraOn(false);
                                    props.handleMonedaCompra(value)
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.carddivisor}></div>
                <div className={styles.cardContainer}>
                    <div>
                        <label className={styles.compraText} htmlFor="cuantorecibis">Cuánto Recibis
                            <input className={styles.compraNumber} type="number" id="cuantorecibis" name="cuantorecibis" disabled={true} value={((props.monedaCompra.compra*props.cuantoEnvias)/props.monedaVenta.venta).toFixed(2)}/>
                        </label>
                    </div>
                    <div className={styles.cardCotizaciones}>
                        {(() => {
                            const data = props.cotizaciones.find(data => {
                                return props.monedaVenta.moneda === data.moneda
                            });
                            return <Card
                                flag={`https://resources.cambioposadas.com.ar/flags/${data.moneda}.png`}
                                name={data.name}
                                buyPrice={data.compra}
                                sellPrice={data.venta}
                            >
                                <img src={charetDown} alt='charet-down' id={'venta'} onClick={handleDropdown}/>
                            </Card>
                        })()}
                        <div className={(charetVentaOn) ? styles.dropdownContainer: styles.dropdownHide}>
                            <Cotizaciones cotizaciones={props.cotizaciones} handleChange={(value)=> {
                                setCharetVentaOn(false);
                                props.handleMonedaVenta(value)
                            }}/>
                        </div>
                    </div>
                </div>
            </FormularioCard>
        </StepHeader>
	);
};

export default FormularioCompra;