import React from 'react'
import styles from './UsCard.module.scss'
import main from '../../Main.module.scss'

export const UsCardSmall = (props) => {
    return (
        <div className={`${styles.card__container}  ${styles.card__container__small} `}>
            <img src={props.image} alt="card Image"/>
        </div>
    )
}

export default UsCardSmall;