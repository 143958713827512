import React from 'react';
import styles from './OperationSuccess.module.scss'
import Modal from '../Modal/Modal';

import successOperation from '../../../Assets/images/successoperation.svg';
import {Link} from 'react-router-dom';

const GeneralModal = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <div className={styles.modal}>
                <div>
                    <p className={styles.modaltitle}>¡La operación se realizó con éxito!</p>
                    <p className={styles.modalsubtitle}>Te enviamos un mail con la información que completaste</p>
                </div>
                <div className={styles.modalimgContainer}>
                    <img className={styles.modalimg} src={successOperation} alt='success'/>
                </div>
                <div className={styles.modalbuttonContainer}>
                    <Link to="/" className={styles.modalcontinuar}>Continuar</Link>
                </div>
            </div>
        </Modal>
       
    )
}

export default GeneralModal;