import Cookies from 'js-cookie';

const IsLogedIn = () => {
    if (Cookies.get('access-Token-Cambio-Posadas') == ''){
      return false;
    }
    if (Cookies.get('access-Token-Cambio-Posadas') !== null) {
        if (Cookies.get('access-Token-Cambio-Posadas') !== undefined) {
          return true;
        }
    }
}

const PostHook = (url, data, headers) => {
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: headers
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch.');
    }
    return response.json()
  })
  .catch(err => {
    throw err;
  });
};

const GetHook = (url, headers) => {
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch.');
    }
    return response.json()
  })
  .catch(err => {
    throw err;
  });
};

const DeleteHook = (url, headers) => {
  return fetch(url, {
    method: 'DELETE',
    headers: headers
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch.');
    }
    return response.json()
  })
  .catch(err => {
    throw err;
  });
};

export {
  IsLogedIn,
  PostHook,
  GetHook,
  DeleteHook
}

