import React from 'react';
import styles from './RegisterForm.module.scss';
import modalIcon2 from '../../../Assets/images/retira-2.svg';

const Residencia = () => {

    return (
        <div className={styles.residenciaContainer}>
            <img src={modalIcon2} alt="Modal Icon"/>
            <h2>¡Bienvenido!</h2>
            <p>Para comenzar, seleccioná un <br/>tipo de usuario.</p>
            <input type="text" placeholder="Argentino, Residente o Turista"/><br/>
            <div className={styles.buttonContainer}>
                <button disabled={true}>Siguiente</button>
            </div>
        </div>
    )
}

export default Residencia;