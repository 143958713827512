import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styles from './Header.module.scss';
import cambio_logo from '../../Assets/images/cp-header.svg';
import { Hamburguer } from '../UI/Hamburguer';

class Header extends Component {
	state = {
		isScrolling: false,
		isOpened: false
	};

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const scrollCheck = window.scrollY;

			if (scrollCheck > 56) {
				this.setState({ isScrolling: true });
			} else if (window.innerWidth < 600 && scrollCheck > 0) {
				this.setState({ isScrolling: true });
			} else {
				this.setState({ isScrolling: false });
			}
		});
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', null);
	}

	goToSection = async (section) => {
		if (this.props.location.pathname !== '/') {
			await this.props.history.push('/');
			await section();
			await this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
		} else {
			await section();
			await this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
		}
	};

	render() {
		return (
			<section className={`${styles.section} ${this.state.isScrolling && styles.section__2}`}>
				<nav className={`${styles.nav} ${styles.nav__2}`}>
					<div className={`${styles.nav__container} ${styles.nav__container__2}`}>
						<Link to='/'>
							<img className={styles.nav__logo} src={cambio_logo} alt='Cambio Posadas Logo' />
						</Link>
					</div>
					<Hamburguer
						opened={this.state.isOpened}
						clicked={() => this.setState((prevState) => ({ isOpened: !prevState.isOpened }))}
					/>
					<div
						className={`${styles.nav__container} ${styles.nav__container__2} ${styles.nav__container__mobile}`}
					>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToHome)}
							className={styles.nav__text}
						>
							compra/venta
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToAppraise)}
							className={styles.nav__text}
						>
							pizarra
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToOurServices)}
							className={styles.nav__text}
						>
							servicios
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToHowDoesItWork)}
							className={styles.nav__text}
						>
							como funciona
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToReconn)}
							className={styles.nav__text}
						>
							reconocer
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToUs)}
							className={styles.nav__text}
						>
							nosotros
						</button>
						<Link to='/empresas' className={styles.nav__text}>
							división Empresas
						</Link>
					</div>
				</nav>
				<nav className={`${styles.Wrapper} ${this.state.isOpened ? styles.active : ''}`}>
					<ul className={styles.MobileLinks}>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToHome)}
							className={styles.nav__text}
						>
							compra/venta
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToAppraise)}
							className={styles.nav__text}
						>
							pizarra
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToOurServices)}
							className={styles.nav__text}
						>
							servicios
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToHowDoesItWork)}
							className={styles.nav__text}
						>
							como funciona
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToReconn)}
							className={styles.nav__text}
						>
							reconocer
						</button>
						<button
							onClick={() => this.goToSection(this.props.executeScrollToUs)}
							className={styles.nav__text}
						>
							nosotros
						</button>
						<Link
							to='/empresas'
							onClick={() => this.setState((prevState) => ({ isOpened: !prevState.isOpened }))}
							className={styles.nav__text}
						>
							división Empresas
						</Link>
						<button onClick={() => {}} className={styles.nav__text}>
							contacto
						</button>
						<button onClick={() => {}} className={styles.nav__text}>
							iniciar sesión
						</button>
					</ul>
				</nav>
			</section>
		);
	}
}

export default withRouter(Header);
