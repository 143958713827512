import React, { Component } from 'react'
import styles from './FirstLog.module.scss';
import { Formik, Form} from 'formik';
import * as yup from 'yup';
import main from '../../Components/Abstracts/Main.module.scss'
import profileImg1 from '../../Assets/images/bienvenido.png'
import profileImg2 from '../../Assets/images/datos-personales.png'
import profileImg3 from '../../Assets/images/img.png'
import backgroundImage from '../../Assets/images/illustration.svg'
import FieldComponent from '../../Components/Forms/FirstLogForm/FieldComponent'
import ButtonComponent from '../../Components/Forms/FirstLogForm/ButtonComponent'
import FieldImageComponent from '../../Components/Forms/FirstLogForm/FieldImageComponent'



const validationSchema = yup.object({
    date: yup.date(),
    nationality: yup.string().matches('^[a-zA-Z ]+$', 'No puede ingresar caracteres especiales o numeros'),
    DNI: yup.number().lessThan(99999999,'El cuil es demasiado largo').moreThan(1000000, 'El DNI es demasiado corto').typeError('el campo dni debe ser un numero'),
    CUIL: yup.number('Ingrese solo digitos').lessThan(99999999999,'El cuil es demasiado largo').moreThan(100000000, 'El DNI es demasiado corto').typeError('Ingrese solo digitos'),
    phone: yup.number().lessThan(999999999999,'El Telefono puede contener entre 7 a 12 digitos').moreThan(10000000, 'El Telefono puede contener entre 7 a 12 digitos').typeError('Ingrese solo Digitos')


})

class FirstLog extends Component {

    state= {
        activeData: 0,
        activeImage: 0,
        imageFront: null,
        imageBack: null,
        imagePreviewUrl: '',
        fileName: ''
    }

    toggleClass = (containerType) => {
        if(containerType === 'data') {
            this.setState(prevState => ({ activeData: prevState.activeData +1}));
        } else {
            this.setState(prevState => ({ activeImage: prevState.activeImage +1}));
        }
    }

    handleFile = (event, key) => {
        if (event.target.files && event.target.files[0] && event.target.files[0].size < 500000) {
        if (event.target.files[0].type === "image/jpeg"|| event.target.files[0].type === "image/png") {
           
            let reader = new FileReader();

            reader.onload = e => {
                if( key == 'front'){
                    this.setState({ imageFront: e.target.result });
                } else {    
                    this.setState({ imageBack: e.target.result });
                }
            }
            reader.readAsDataURL(event.target.files[0]);

            this.setState({ fileName: event.target.files[0].name.slice(0, 10) + "..." });
        } else {
            this.setState({ fileName: "jpeg/png unicamente" });
        }
        } else {
        this.setState({
            fileName: "No puede superar 500kb"
        });
        }
        // this.props.setImage(event.target.files[0]);
    }


    render() {
        const { activeData, activeImage, imageFront, imageBack } = this.state 
        
        return (
            <>
                <section className={styles.section}>
                    <div className={`${styles.profile__container} ${main.u__margin_top_medium_1} `}>
                        <div className={`${styles.profile__container_sub} ${main.u__margin_top_tiny_1}`}>
                            <img className={styles.profile__image} src={profileImg1} alt="profile image"/>
                            <div className={styles.profile__container_text}>
                                <h2 className={`${styles.profile__header} ${styles.profile__header_1} `}>
                                    ¡Bienvenido Gustavo!
                                </h2>
                                <p className={`${styles.profile__text} ${styles.profile__text_1} ${main.u__margin_top_tiny_2}`}>
                                    Como es tu primera vez en la plataforma, es necesario que completes algunos datos para poder operar. Es simple, nosotros te ayudaremos.
                                </p>
                            </div>
                        </div>
                        
                        <div className={`${styles.profile__container_sub} ${main.u__margin_top_tiny_2} ${main.u__border_radius_2}`}>
                            <img className={styles.profile__image} src={profileImg2} alt=""/>
                            <div className={styles.profile__container_text}>
                                <h2 className={`${styles.profile__header} ${styles.profile__header_2}`}>
                                    Datos personales
                                </h2>
                                <p className={`${styles.profile__text} ${styles.profile__text_2} ${main.u__margin_top_tiny_2}`}>
                                    Aquí completarás datos personales como tu Domicilio, teléfono, nacionalidad, etc.
                                </p>
                                <button onClick={() => this.toggleClass('data')} className={`${styles.profile__button_2} ${styles.profile__button} ${main.u__margin_top_small_1} ${activeData === 0 ? styles.form__active : null}`}>
                                    Completar datos
                                </button>
                                
                                <Formik 
                                initialValues={{date: new Date, nationality:'', DNI: '', CUIL: '', phone: '', state:'', city: '', street: '', CP:'', legalResidence: '', maritalStatus: '', ocupation: ''}} 
                                
                                validationSchema={validationSchema}
                                
                                onSubmit={(data, { setSubmitting }) => {
                                    setSubmitting(true);
                                    // console.log('submit: ' + data);
                                    setSubmitting(false);
                                }}
                                >
                                    {({ values, errors})=> (
                                        <Form>
                                            <div className={`${styles.form__container} ${ activeData === 1 ?  styles.form__active : null} `}>
                                                <FieldComponent label='Fecha de Nacimiento' name='date' type='date'/>        
                                                <FieldComponent label='Nacionalidad' name='nationality' type='input' />
                                                <FieldComponent label='DNI'name='DNI' type='input' />
                                                <FieldComponent label='CUIL'name='CUIL' type='input' />
                                                <FieldComponent label='Telefono' name='phone' type='input'/>
                                                <ButtonComponent toggleClass={()=> this.toggleClass('data')} text='Guardar y Continuar'/>
                                            </div>
                                            <div className={`${styles.form__container} ${ activeData === 2 ?  styles.form__active : null} `}>

                                                <FieldComponent label='Provincia' name='state' type='input'/>

                                                <FieldComponent label='Localidad' name='city' type='input' />

                                                <FieldComponent label='Calle y Numero' name='street' type='input' />

                                                <FieldComponent label='Codigo Postal' name='CP' type='input'/>

                                                <ButtonComponent toggleClass={()=> this.toggleClass('data')} text='Guadar y Continuar'/>
                                            </div>
                                            <div className={`${styles.form__container} ${ activeData === 3 ?  styles.form__active : null} `}>

                                                <FieldComponent label='Residencia Legal' name='legalResidence' type='input'/>

                                                <FieldComponent label='Estado Civil' name='maritalStatus' type='input'/>

                                                <FieldComponent label='Ocupacion' name='ocupation' type='input'/>

                                                <ButtonComponent toggleClass={()=> this.toggleClass('data')} text='Guadar y Continuar'/>
                                            </div>
                                        </Form>    
                                    )}
                                </Formik>
                            </div>
                        </div>
                        <div className={`${styles.profile__container_sub} ${main.u__margin_top_tiny_2} ${main.u__border_radius_2} ${ activeData ?  styles.profile__active : null} `}>
                            <img className={styles.profile__image}src={profileImg3} alt=""/>
                            <div className={styles.profile__container_text}>
                                <h2 className={`${styles.profile__header} ${styles.profile__header_3}`}>
                                    Imágenes
                                </h2>
                                <p className={`${styles.profile__text} ${styles.profile__text_3} ${main.u__margin_top_tiny_2}`}>
                                    Tené a mano tu DNI o Pasaporte porque necesitamos que nos adjuntes unas imágenes. 
                                </p>
                                
                                <button onClick={() => this.toggleClass()} className={`${styles.profile__button} ${styles.profile__button_3} ${main.u__margin_top_small_1} ${ activeImage === 0 ? styles.form__active : null} `}>
                                    Adjuntar Imágenes
                                </button>
                                <Formik initialValues = {{DNI: null}}>
                                    <Form>
                                        <div className={`${styles.form__container} ${ activeImage === 1 ?  styles.form__active : null} `}>
                                            <FieldImageComponent type="file" id='file' image={this.state.imageFront} name="front" handleFile={this.handleFile}  label="Subir foto frente del dni"/>
                                            <FieldImageComponent type="file" id='file' image={this.state.imageBack} name="back" handleFile={this.handleFile}  label="Subir foto frente del dni" key='back'/>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.profile__container}`}>
                        
                        <img className={styles.profile__background_image} src={backgroundImage} alt="profile background Image"/>
                        
                    </div>
                </section>
            </>
        )
    }
}


export default FirstLog;