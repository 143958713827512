import React from 'react';
import styles from './Hamburguer.module.scss';

export const Hamburguer = ({ opened, clicked }) => {
	return (
		<div className={`${styles.StyledHam} ${opened ? styles.active : ''}`} onClick={clicked}>
			<span />
			<span />
			<span />
			<span />
		</div>
	);
};
