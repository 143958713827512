import React from 'react';
import styles from './Modal.module.scss'

const Modal = (props) => {

    return (
        <div>
            <div className={styles.Modal} onClick={props.onClose}/>
            <div className={styles.container}>
                {props.children}
            </div>
        </div>

    )
}

export default Modal;
