import React from 'react';
import Home from '../Main/Home/Home';
import Appraise from './Appraise/Appraise';
import MobileApp from './MobileApp/MobileApp';
import OurServices from './OurServices/OurServices';
import { HowDoesItWork } from './HowDoesItWork/HowDoesItWork';
import Us from './Us/Us';
import Map from './Map/Map';
import ContactUs from './ContactUs/ContactUs';

const Main = (props) => {

  return (
    <div>
        <Home homeRef={props.homeRef} />
        <Appraise appraiseRef={props.appraiseRef}/>
        <MobileApp/>
        <OurServices ourServicesRef={props.ourServicesRef}/>
        <HowDoesItWork howDoesItWorkRef={props.howDoesItWorkRef} reconnRef={props.reconnRef}/>
        <Us usRef={props.usRef}/>
        <Map/>
        <ContactUs/>
    </div>
  );
}

export default Main;
