import React from 'react';
import Card from './Card/Card';

const Cotizaciones = (props) => {

	return (
        <div>
            {props.cotizaciones.map((data, key)=> {
                return (
                    <Card
                        key={key}
                        flag={`https://resources.cambioposadas.com.ar/flags/${data.moneda}.png`}
                        name={data.name}
                        buyPrice={data.compra}
                        sellPrice={data.venta}
                    />
                );
            })}
        </div>
	);
};

export default Cotizaciones;