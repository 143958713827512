import React, {useEffect} from 'react';
import styles from './Resumen.module.scss';
import StepHeader from '../FormularioHeader';
import FormularioCard from '../FormularioCard';

import charetDown from '../../../Assets/images/charetdown.svg';

import transferencia from '../../../Assets/images/transferencia.svg';
import quintana from '../../../Assets/images/quintana.svg';
import central from '../../../Assets/images/central.svg';

const Resumen = (props) => {

    useEffect(() => {
        props.isCompleted(true);
    }, [])

	return (
        <StepHeader title={props.title} step={props.step}>
            <FormularioCard titleCard={props.titleCard}>
                <div className={styles.resumenContainer}>
                    <div className={styles.row}>
                        <div className={styles.modalidad}>
                            <p className={styles.title}>Cuanto Envias</p>
                            <div className={styles.icon}>
                                <img src={`https://resources.cambioposadas.com.ar/flags/${props.operacion.monedacompra}.png`} alt={props.operacion.monedacompra}/>
                                <p>{props.operacion.envias}</p>
                            </div>
                            <p className={styles.nombrecompleto}>{props.operacion.nombrecompletocompra}</p>
                        </div>
                        <div className={styles.charet}>
                            <img src={charetDown} alt='charet'/>
                        </div>
                        <div className={styles.modalidad}>
                            <p className={styles.title}>Cuanto Recibis</p>
                            <div className={styles.icon}>
                                <img src={`https://resources.cambioposadas.com.ar/flags/${props.operacion.monedaventa}.png`} alt={props.operacion.monedaventa}/>
                                <p>{props.operacion.recibis}</p>
                            </div>
                            <p className={styles.nombrecompleto}>{props.operacion.nombrecompletoventa}</p>
                        </div>
                    </div>
                    <div className={styles.carddivisor}></div>
                    <div className={styles.row}>
                        <div className={styles.modalidad}>
                            <p className={styles.title}>Envío del dinero</p>
                            <div className={styles.icon}>
                                {(() => {
                                    switch (props.selectedPayMethod) {
                                        case 'central':
                                            return (
                                                <img src={central} alt={central}
                                                />
                                            );
                                        case 'quintana':
                                            return (
                                                <img src={quintana} alt={quintana}
                                                />
                                            );
                                        case 'enviotransferencia':
                                            return (
                                                <img src={transferencia} alt={transferencia}
                                                />
                                            );
                                        default: 
                                            return '';
                                    }
                                })()}
                            </div>
                            {(() => {
                                switch (props.selectedPayMethod) {
                                    case 'central':
                                        return (
                                            <p className={styles.nombrecompleto}>Casa Central</p>
                                        );
                                    case 'quintana':
                                        return (
                                            <p className={styles.nombrecompleto}>Sucursal Quintana</p>
                                        );
                                    case 'enviotransferencia':
                                        return (
                                            <p className={styles.nombrecompleto}>Transferencia Bancaria</p>
                                        );
                                    default: 
                                        return '';
                                }
                            })()}
                            
                        </div>
                        <div className={styles.modalidad}>
                            <p className={styles.title}>Recepción del dinero</p>
                            <div className={styles.icon}>
                                {(() => {
                                    switch (props.selectedDeliveryMethod) {
                                        case 'sucursal':
                                            return (
                                                <img src={central} alt={central}
                                                />
                                            );
                                        case 'delivery':
                                            return (
                                                <img src={quintana} alt={quintana}
                                                />
                                            );
                                        case 'transferencia':
                                            return (
                                                <img src={transferencia} alt={transferencia}
                                                />
                                            );
                                        default: 
                                            return '';
                                    }
                                })()}
                            </div>
                            {(() => {
                                switch (props.selectedDeliveryMethod) {
                                    case 'sucursal':
                                        return (
                                            <p className={styles.nombrecompleto}>Retirar - Casa Central</p>
                                        );
                                    case 'delivery':
                                        return (
                                            <p className={styles.nombrecompleto}>Delivery a domicilio</p>
                                        );
                                    case 'transferencia':
                                        return (
                                            <p className={styles.nombrecompleto}>Transferencia Bancaria</p>
                                        );
                                    default: 
                                        return '';
                                }
                            })()}
                            
                        </div>
                    </div>
                    <div className={styles.carddivisor}></div>
                    <div>
                        <div className={styles.row}>
                            <div className={styles.modalidad}>
                                <p className={styles.title}>Fecha</p>
                                <p className={styles.nombrecompleto}>30/09/2019</p>
                            </div>
                            <div className={styles.modalidad}>
                                <p className={styles.title}>Hora</p>
                                <p className={styles.nombrecompleto}>11:18 hs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </FormularioCard>
            <div className={styles.Terms}>
                <p>Al continuar con la transacción estás aceptando los <a href="/terminosycondiciones" target="_blank">Términos y Condiciones de Servicio de Cambio Posadas</a>. Pero primero, precisamos que nos confirmes si formas parte o no de alguna de las siguientes categorías:</p>
                <div className={styles.itemContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} onChange={(e) => props.handleCompletedSujetoObligado(e.target.value)}>Soy Sujeto Obligado
                            <input type="checkbox" defaultChecked={props.sujetoObligado} />
                            <span className={styles.checkmark}></span>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} onChange={(e) => props.handleCompletedPEP(e.target.value)}>Soy Persona Expuesta Políticamente
                            <input type="checkbox" defaultChecked={props.PEP} />
                            <span className={styles.checkmark}></span>
                        </label>
                    </div>
                </div>
            </div>                 
        </StepHeader>
	);
};

export default Resumen;