import React, {useState} from 'react';
import styles from './Login.module.scss';
import modalIcon from '../../../Assets/images/selecciona-2.svg';
import {PostHook, GetHook} from '../../../Helpers/Services';
import {environment} from '../../../env';
import Cookies from 'js-cookie';

const Login = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.persist();
        props.handleLoading(true);
        props.onClose(event);
        let formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);

        PostHook(
            'https://api.cambioposadas.com.ar/oauth/token',
            formData,
            {'Authorization' : environment.basic}
        ).then(data => {
            Cookies.set('access-Token-Cambio-Posadas', data.access_token);
            return GetHook('https://api.cambioposadas.com.ar/public/user/mine', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
            .then((data) => {
                props.handleUser(data);
                props.handleLoading(false);
            });
        })
        .catch(err => {
            props.handleLoading(false);
            throw err;
        });
    }

    return (
        <div className={styles.container}>
            <img className={styles.icon} src={modalIcon} alt="Modal Icon"/>
            <h2 className={styles.title}>¡Hola de vuelta!</h2>
            <div className={styles.form}>
                <input placeholder='Usuario' type='text' name='user' onChange={(e)=>{setUsername(e.target.value)}}/>
                <input placeholder='Contraseña' name='password' type='password' onChange={(e)=>{setPassword(e.target.value)}}/>
                <a href='#' className={styles.forgot_password}>Olvide mi contraseña</a>
                <button onClick={handleSubmit} id="loginSubmit" className={(!username || !password) ? styles.disabled : styles.button} disabled={(!username || !password)}>Iniciar Sesion</button>
            </div>             
        </div>
    )
}

export default Login;