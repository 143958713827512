import React from 'react';
import main from '../../Abstracts/Main.module.scss';
import styles from './Card.module.scss';

const Card = (props) => {

    return (
        <div className={`${styles.card} ${main.u__border_radius_1}`}>
            <div className={styles.card__flagBox}>
                <img className={styles.card__flag} src={props.flag} alt="flag-logo"/>
                <p className={styles.card__flagName}>{props.name}</p>
            </div>
            <div className={styles.card__buySellBox}>
                <p className={styles.card__buySellPrice}>$ {props.buyPrice}</p>
                <span className={styles.card__buySell}>Compra</span>
            </div>
            <div className={styles.card__buySellBox}>
                <p className={styles.card__buySellPrice}>$ {props.sellPrice}</p>
                <span className={styles.card__buySell}>Venta</span>
            </div>
            {props.children}
        </div>
    )
}

export default Card;