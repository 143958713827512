import React from 'react';
import styles from './index.module.scss';

const FormularioCard = (props) => {

	return (
        <div className={styles.formularioContainer}>
            <div className={styles.cardTitle}>
                <div className={styles.title}>{props.titleCard}</div>
                <div className={styles.titledivisor}></div>
                {props.children}
            </div>
        </div>
	);
};

export default FormularioCard;