import React, {useState} from 'react';
import Modal from '../Modal/Modal';
import styles from './Sign.module.scss';
import Register from './Register/Register';
import Login from '../../Forms/LoginForm/Login';
import ModalTabs from './ModalTabs/ModalTabs';

const Sign = (props) => {

    const [activeTab, setActiveTab] = useState('login');

    const handleTab = (tabValue) => {
        setActiveTab(tabValue)
    }

    return (
        <Modal onClose={props.onClose}>
            <div className={styles.modal}>
                <ModalTabs activeTab={activeTab} handleTab={handleTab} />
                {(() => {
                    switch (activeTab) {
                        case 'login':
                            return <Login activeTab={activeTab} onClose={props.onClose} handleUser={props.handleUser} handleLoading={props.handleLoading}/>;
                        case 'register':
                            return <Register activeTab={activeTab}/>;
                        default:
                            return <Login/>;
                    }
                })()}
            </div>
        </Modal>
    )
}

export default Sign;