import React from 'react'
import Header from '../Header/HeaderOne'
import HeaderTwo from '../Header/HeaderTwo';

export const Layout = (props) => {
    return (
        <div>
            <Header />
            <HeaderTwo 
            executeScrollToHome={props.executeScrollToHome}
            executeScrollToAppraise={props.executeScrollToAppraise}
            executeScrollToOurServices={props.executeScrollToOurServices}
            executeScrollToHowDoesItWork={props.executeScrollToHowDoesItWork}
            executeScrollToReconn={props.executeScrollToReconn}
            executeScrollToUs={props.executeScrollToUs}
            />
        </div>
    )
}
