import React from 'react'
import styles from './ContactUsForm.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import logo from '../../../Assets/images/cp-footer.svg'

export const ContactUsForm = () => {
    return (
        <section className={styles.section}>
            <form className={styles.form}>
                <img className={`${styles.form__logo} ${main.u__margin_top_small_1}`} src={logo} alt="Form Logo"/>
                <div className={`${styles.form__leyend} ${main.u__margin_top_small_1}`}>
                    <p className={main.text__paragraph_2}>Si necesitas más información o ayuda, dejanos tus datos y te responderemos de vuelta</p>
                </div>
                <input className={`${styles.form__input} ${main.u__margin_top_small_1}`} type="text" placeholder='Nombre y Apellido'/>
                <input className={`${styles.form__input} ${main.u__margin_top_tiny_2}`} type="text" placeholder='Email'/>
                <input className={`${styles.form__input} ${main.u__margin_top_tiny_2}`} type="text" placeholder='Teléfono'/>
                <textarea className={`${styles.form__input} ${styles.form__input_textarea} ${main.u__margin_top_tiny_2}`} type="textbox" placeholder='Escribe aqui tu mensaje ...'/>
                <button className={`${styles.form__submit} ${main.u__border_radius_1} ${main.u__margin_top_small_1}`}>Enviar</button>
            </form>
        </section>
    )
}

export default ContactUsForm;