import React from 'react'
import styles from './ContactUs.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import Form from '../../../Components/Forms/ContactUsForm/ContactUsForm'
import SocialMediaButtons from '../../../Components/Abstracts/Buttons/SocialMediaButtons/SocialMediaButtons'


export const ContactUs = () => {
    return (
        <section className={`${styles.section} ${main.u__margin_top_medium_1}`}>
            <div className={main.u__margin_top_medium_1}>
                <Form/>    
            </div>     
            {/* <div className={`${styles.leyend} ${main.u__margin_top_medium_1}`}>
                <p className={`${styles.leyend__text} ${main.text__tiny_paragraph_1}`}>La primera plataforma creada en Argentina que te permite acceder al mercado libre de cambios regulado por el Banco Central de la República Argentina, desde tu casa, negocio u oficina, las 24 horas y los 365 días</p>
                <p className={`${styles.leyend__copyright} ${main.text__tiny_paragraph_1} ${main.u__margin_top_small_1}`}>© 2019 Copyright CambioPosadas SRL / Design by paisanos.io</p>
            </div>  
            <div className={main.u__margin_center}>      
                <SocialMediaButtons/>
            </div> */}
        </section>
    )
}

export default ContactUs;