
import React, {useState, useEffect} from 'react';
import styles from './AlertasTable.module.scss';
import AlertCard from '../Abstracts/Cards/AlertCard/AlertCard';
import upImage from '../../Assets/images/alerta-b.svg';
import {IsLogedIn, GetHook} from '../../Helpers/Services';
import Cookies from 'js-cookie';
import CreateAlertForm from '../Forms/CreateAlertForm/CreateAlertForm';
import LoaderSpinner from '../../Helpers/LoaderSpinner';
import GeneralModal from '../Modals/GeneralModal/GeneralModal';


const AlertasTable = () => {

    const [alertData, setData] = useState([]);
    const [createAlert, setCreateAlert] = useState(false);
    const [spinnerLoading, setSpinnerloading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        setSpinnerloading(true);
        if(IsLogedIn()) {
            GetHook('https://api.cambioposadas.com.ar/public/alert', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
            .then((data) => {
                setData(data);
                setSpinnerloading(false);
            });
        }
    },[]);

    const createNewAlert = () => {
        setCreateAlert(!createAlert);
    }

    const handleAlertChanges = (data) => {
        setData(data);
    }

    return (
        <div>
            {(spinnerLoading) ? <LoaderSpinner/> : (
                <div className={styles.AlertasTableBox}>
                    {
                        deleteModal && 
                            <GeneralModal
                                onClose={()=>setDeleteModal(false)}
                                title={'Alerta Borrada'}
                                subtitle={'Su alerta fue borrada exitosamente!'}
                                buttonDialog={'Continuar'}
                                exitModal={()=>setDeleteModal(false)}
                                type='warning'    
                            />
                    }
                    <div className={styles.AlertasTableRow}>
                        {
                            alertData.map((result, key)=>{
                                return (
                                    <AlertCard
                                        key={key}
                                        flag={`https://resources.cambioposadas.com.ar/flags/${result.currencyId}.png`}
                                        name={result.currencyId}
                                        price= {result.value}
                                        typeAlert={(result.bid)? 'buy': 'sell'}
                                        currencyName={result.currencyName}
                                        alertId={result.id}
                                        handleAlertChanges={handleAlertChanges}
                                        setDeleteModal={(value)=>setDeleteModal(value)}
                                    />
                                );
                            })
                        }
                        <div className={styles.createAlertContainer} >
                            <div className={styles.createAlertButton} onClick={createNewAlert}>
                                <span className={(createAlert) ? styles.minus : styles.plus}></span>
                            </div>
                            <div className={styles.createAlertText}>
                                <p onClick={createNewAlert}>Crear Alerta</p>
                            </div>
                        </div>
                        {
                            createAlert &&
                            <CreateAlertForm handleAlertChanges={handleAlertChanges}/>
                        }
                    </div>
                    <div className={styles.iconAlerta}>
                        <div className={styles.imgContent}>
                            <img alt='BullImage' src={upImage}/>
                        </div>
                        <div className={styles.textContainer}>
                            <h1>Alertas</h1>
                            <p>
                                Crea una alerta para saber cuando la
                                moneda que vos quieras alcance cierto valor.
                                Así podrás estar al tanto de cualquier
                                cambio en la cotización.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}

export default AlertasTable;