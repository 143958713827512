import React from 'react';
import styles from './Us.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import SmallCard from '../../../Components/Abstracts/Cards/UsCard/UsCardSmall';
import BigCard from '../../../Components/Abstracts/Cards/UsCard/UsCardBig';
import cardImageOne from '../../../Assets/images/nosotros1.jpg';
import cardImageTwo from '../../../Assets/images/nosotros2.jpg';
import cardImageThree from '../../../Assets/images/nosotros3.jpg';

export const Us = (props) => {
	return (
		<section className={styles.section} ref={props.usRef}>
			<div className={`${styles.text__container} ${main.u__margin_top_medium_1}`}>
				<h2 className={main.text__secondary_heading_1}>Nosotros</h2>
				<h3 className={`${main.text__subtitle} ${main.u__margin_top_small_1} ${main.u__after_line_1}`}>
					Cambio Posadas es una entidad cambiara regulada por el Banco Central de la Republica Argentina. Ha
					sido la primera entidad financiera en contar con un manual normativo para operaciones de delivery,
					la primera en desarollar una App para smartphones donde los clientes pueden acceder al Mercado Libre
					de Cambios sin moverse de su casa, negocio o empresa y la primera entidad cambiaria en ofrecer un
					servicio de cambio de moneda extranjera por Internet.
				</h3>
			</div>
			<div className={`${styles.card__container} ${main.u__margin_top_medium_1}`}>
				<SmallCard image={cardImageOne} />
				<SmallCard image={cardImageTwo} />
				<BigCard image={cardImageThree} />
			</div>
		</section>
	);
};

export default Us;
