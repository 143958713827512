import React, { Component } from 'react'
import styles from './MobileApp.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss'
import appimage from '../../../Assets/images/app.svg'
import google from '../../../Assets/images/google-play-badge-bb.svg';
import apple from '../../../Assets/images/appleApp.svg';


export default class MobileApp extends Component {
    render() {
        return (
            <section className={`${styles.section} ${main.u__margin_top_medium_1}`}>
                <div className={styles.container}>
                    <div className={styles.container__text}>
                            <div className={`${main.text__secondary_heading_2} ${main.u__margin_top_medium_1}`}>
                                El mejor cambio está en tus manos.
                            </div>
                            <div className={`${main.text__paragraph_1} ${main.u__margin_top_small_1}`}>
                                Descargá nuestra aplicación totalmente gratis y comprá moneda extranjera de forma FÁCIL Y SEGURA sin moverte de tu casa. 
                            </div>
                            <div className={`${main.text__paragraph_1} ${main.u__margin_top_small_1}`}>
                                La primera plataforma creada en Argentina que te permite acceder al mercado libre de cambios regulado por el Banco Central de la República Argentina, desde tu casa, negocio u oficina, las 24 horas, los 365 días de año.
                            </div>
                            <div className={`${styles.container__buttons} ${main.u__margin_top_small_1}`}>
                                <a href="https://itunes.apple.com/ar/app/cambio-posadas/id1345520805?mt=8"><img className={styles.button} src={apple} alt="apple app button"/></a>
                                
                                <a href="https://play.google.com/store/apps/details?id=com.paisanoscreando.cambioposadas"><img className={styles.button} src={google} alt="google app button"/></a>
                            </div>
                    </div>

                    <div className={styles.container__image}>
                        <img className={styles.image} src={appimage} alt="app_mobile"/>
                    </div>
                </div>
            </section>
        )
    }
}
