import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './Pages/Main/Main';
import FirstLog from './Pages/FirstLog/FirstLog';
import { Layout } from './Components/Layout/Layout';
import Empresas from './Pages/Empresas/Empresas';
import Perfil from './Pages/Perfil/Perfil';
import CompraVenta from './Pages/CompraVenta/CompraVenta';
import Footer from './Components/Footer/Footer';

function App() {
	const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 120);

	const homeRef = React.createRef();
	const appraiseRef = React.createRef();
	const ourServicesRef = React.createRef();
	const howDoesItWorkRef = React.createRef();
	const reconnRef = React.createRef();
	const usRef = React.createRef();

	const executeScrollToHome = () => scrollToRef(homeRef);
	const executeScrollToAppraise = () => scrollToRef(appraiseRef);
	const executeScrollToOurServices = () => scrollToRef(ourServicesRef);
	const executeScrollToHowDoesItWork = () => scrollToRef(howDoesItWorkRef);
	const executeScrollToReconn = () => scrollToRef(reconnRef);
	const executeScrollToUs = () => scrollToRef(usRef);
	return (
		<div className='App'>
			<BrowserRouter>
				<Layout
					executeScrollToHome={executeScrollToHome}
					executeScrollToAppraise={executeScrollToAppraise}
					executeScrollToOurServices={executeScrollToOurServices}
					executeScrollToHowDoesItWork={executeScrollToHowDoesItWork}
					executeScrollToReconn={executeScrollToReconn}
					executeScrollToUs={executeScrollToUs}
				/>
				<Switch>
					<Route
						exact
						path='/'
						render={(props) => {
							return (
								<Home
									homeRef={homeRef}
									appraiseRef={appraiseRef}
									ourServicesRef={ourServicesRef}
									howDoesItWorkRef={howDoesItWorkRef}
									reconnRef={reconnRef}
									usRef={usRef}
								/>
							);
						}}
					/>
					<Route exact path='/FirstLog' component={FirstLog} />
					<Route exact path='/empresas' component={Empresas} />
					<Route exact path='/perfil' component={Perfil} />
					<Route exact path='/compraventa' component={CompraVenta} />
				</Switch>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
