import React from 'react'
import styles from './Footer.module.scss';
import main from '../Abstracts/Main.module.scss';
import logo from '../../Assets/images/cp-footer.svg';
import SocialMediaButtons from '../Abstracts/Buttons/SocialMediaButtons/SocialMediaButtons';

const Footer = () => {
    return (
        <section className={`${styles.footer}`}>
            <div className={styles.footer__container}>
                <img className={styles.footer__logo} src={logo} alt=""/>
                <p className={`${styles.footer__text} ${main.text__paragraph_2} ${main.u__margin_top_small_1}`}>La primera plataforma creada en Argentina que te permite acceder al mercado libre de cambios regulado por el Banco Central de la República Argentina, desde tu casa, negocio u oficina, las 24 horas y los 365 días de año.</p>
                <p className={`${styles.footer__copyright} ${main.text__paragraph_2}  ${main.u__margin_top_small_1}`}>© 2019 CambioPosadas SA / Design by paisanos.io</p>
            </div>
            <div className={styles.footer__container}>
                <h2 className={main.text__secondary_heading_3}>Accesos</h2>
                <a className={`${styles.footer__link} ${main.text__paragraph_1} ${main.u__margin_top_tiny_1}`} href="#">Home</a>
                <a className={`${styles.footer__link} ${main.text__paragraph_1} ${main.u__margin_top_tiny_1}`} href="#">Pizarra</a>
                <a className={`${styles.footer__link} ${main.text__paragraph_1} ${main.u__margin_top_tiny_1}`} href="#">División Empresas</a>
                <a className={`${styles.footer__link} ${main.text__paragraph_1} ${main.u__margin_top_tiny_1}`} href="#">Nosotros</a>
            </div>
            <div className={styles.footer__container}>
                <h2 className={main.text__secondary_heading_3}>Contacto</h2>
                <a href="#" className={`${styles.footer__link} ${main.text__paragraph_1} ${main.u__margin_top_tiny_1}`}>0800 - 220 - CAMBIO</a>
                <SocialMediaButtons/>
            </div>
        </section>
    )
}

export default Footer;