import React from 'react'
import { Field, useField} from 'formik';
import main from '../../Abstracts/Main.module.scss';
import styles from './FirstLogForm.module.scss';
import inputLogo from '../../../Assets/images/subir-img.png';

export const FieldImageComponent = (props) => {

    const [field, meta] = useField(props);

    return (
        <div className={`${styles.form__image_input_container} ${main.u__margin_top_small_1}`}>
            <img className={`${styles.form__image_preview} ${props.image ? styles.form__image_preview_active : null}`} src={props.image} alt=""/>
            <label className={styles.form__image_label} htmlFor={field.name}/>
            <img className={styles.form__image_logo}src={inputLogo}/>
            <p className={styles.form__image_text}>{props.label}</p>
            <input className={styles.form__image_input} onChange={(e)=> props.handleFile(e, field.name)} id={field.name} type="file" accept='.jpg, .png' />
        </div>
    )
}

export default FieldImageComponent;