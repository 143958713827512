import React, {useState, useEffect} from 'react';
import './ProfileForm/ProfileForm.css';

const SelectInput = (props) => {
    const [inputValue, setiInputValue] = useState(props.value);

    // REFACTOR => VALIDATIONS GO HERE

    useEffect(() => {
        setiInputValue(props.value);
    }, [props.value]);

    let inputIcon;
    let styleInput;
    if (props.isEditable !== 'disabled') {
        if(inputValue) {
            inputIcon = <div className='validationSuccessIcon'>✓</div>;
            styleInput = 'textSuccess'
        } else {
            inputIcon = <div className='validationDeniedIcon'>X</div>;
            styleInput = 'textDenied'
        }
    }

    return (
        <div className='inputWrapper'>
            <div>
                <label className='labelBoxElement'>{props.label}</label>
            </div>
            <div className='textInputContainer'>
                {(props.isEditable === 'disabled' ) &&
                    <div  className='textInput fakeInput'>{inputValue}</div>
                }

                {(props.isEditable !== 'disabled' ) &&
                    <select className={`textInput ${styleInput}`} name={props.name} disabled={props.isEditable} placeholder={props.placeholder} value={inputValue} onChange={e => {
                        setiInputValue(e.target.value)
                        props.handleChange(e.target)
                    }}>
                        <option value='seleccionar' defaultValue>Seleccionar</option>
                        {props.selectOptions.map((data, key)=> {
                            return <option value={data} key={key}>{data}</option>
                        })}
                    </select>
                }
                {inputIcon}
            </div>
        </div>
    );
}

export default SelectInput;