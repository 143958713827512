import React from 'react'
import styles from './SocialMediaButtons.module.scss'
import main from '../../Main.module.scss'
import twitter from '../../../../Assets/images/twitter.png'
import facebook from '../../../../Assets/images/facebook.png'
import instagram from '../../../../Assets/images/instagram.png'
import linkedIn from '../../../../Assets/images/linkedin.png'

export const SocialMediaButtons = () => {
    return (
        <div className={`${styles.link__container} ${main.u__margin_top_small_1}`}>
            <a className={styles.link__element} href="https://twitter.com/cambioposadas" target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="twitter link"/>
            </a>
            <a className={styles.link__element} href="https://www.facebook.com/cambioposadas" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="facebook link"/>
            </a>
            <a className={styles.link__element} href="https://www.instagram.com/cambioposadas/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="instagram link"/>
            </a>
            <a className={styles.link__element} href="https://www.linkedin.com/company/cambioposadas/about/" target="_blank" rel="noopener noreferrer">
                <img src={linkedIn} alt="linkedin link"/>
            </a>
        </div>
    )
}

export default SocialMediaButtons;
