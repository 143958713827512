import React from 'react';
import styles from './ModalTabs.module.scss';

const ModalTabs = (props) => {

    const onChangeTab = (e) => {
        props.handleTab(e.target.id);
    }

    return (
        <div className={styles.container}>
            <button id='login' className={`${(props.activeTab === 'login') ? styles.button_green : styles.button_white} ${styles.button}`} onClick={onChangeTab}>Iniciar sesion</button>
            <button id='register' className={`${(props.activeTab === 'register') ? styles.button_green : styles.button_white} ${styles.button}`} onClick={onChangeTab}>Registrarme</button>
        </div>
    )
}

export default ModalTabs;