import React from 'react'
import styles from './_serviceCard.module.scss'
import main from '../../../Abstracts/Main.module.scss'

const ServiceCard = (props) => {
    const {setActive, value, activeValue} = props

    return (
        <div 
        className={`${styles.card} ${main.u__border_radius_1} ${value === activeValue ? styles.card__active : ""}`} 
        onMouseOver={() => setActive(value)}>
            <img className={`${styles.card__image} ${main.u__border_radius_1}`} src={props.backgroundImage} alt='background_image'/>
            <div className={styles.card__subtitle_container}>
                <p className={styles.card__subtitle}>{props.subtitle}</p>
            </div>
            <div className={styles.card__title_container}>
                <p className={styles.card__title}>{props.title}</p>
            </div>
            <div className={styles.card__text_container}>
                <p className={styles.card__text}> {props.text}</p>
            </div>
           <a className={`${styles.card__btn} ${main.u__border_radius_1} ${main.u__text_align_center}`}>{props.btnText}</a>
        </div>
    )  
}

export default ServiceCard;