import React, {useRef} from 'react';
import './ProfileForm/ProfileForm.css';


const UploadIdInput = (props) => {

    const fileUploader = useRef();

    const actiondelete = () => {
        alert('delete images');
    }

    return (
        <div className={(props.isAvailable)? 'uploadModalBox' : 'uploadModalBoxDisable'}>
            <div className='uploadInputs'>
                <label htmlFor='file'>Subir Nueva Foto</label>
                <input type="file" id='file' name={props.name} hidden ref={fileUploader} onChange={(e)=>props.handleUpdateUser(e.target)}/>
            </div>
            <div className='uploadInputs'>
                <label id='delete' onClick={actiondelete}>Eliminar</label>
            </div>
        </div>
    );
};

export default UploadIdInput;