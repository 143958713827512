import React, { useEffect } from 'react';
import styles from './Empresas.module.scss';
import carousel1 from '../../Assets/images/carousel-1.jpg';
import carousel2 from '../../Assets/images/carousel-2.jpg';
import carousel3 from '../../Assets/images/carousel-3.jpg';

const Empresas = () => {
	useEffect(() => {
		window.scroll({ top: 0, left: 0 });
	}, []);
	return (
		<section className={styles.container}>
			<h1>División empresas</h1>
			<h3>Cómo funciona la División Empresas de Cambio Posadas</h3>
			<div className={styles.textContainer}>
				<div className={styles.textContainer__frst}>
					<p>
						Nuestros Oficiales de Cambio visitan a nuestros clientes corporativos, la idea es que no tengan
						que venir nunca a nuestras sucursales, ni siquiera para completar su legajo o para pagar o
						liquidar una operación de cambio. Contamos con una amplia gama de servicios para reducir el
						riesgo de volatilidad del tipo de cambio y ayudarlo a mitigar ese riesgo.
					</p>
					<p>
						El Oficial de Cambio asignado a su empresa trabajará con usted para abordar de manera proactiva
						las necesidades de FX de su negocio.
					</p>
					<p>
						A través de todos los canales y medios electrónicos que ponemos a disposición de nuestros
						clientes corporativos, usted accede a una plataforma on-line donde podrá:
					</p>
					<p>
						Negociar tipos de cambio en tiempo real - Concertar operaciones y liquidarlas - Operar una
						amplia variedad de divisas
					</p>
				</div>
				<div className={styles.textContainer__scnd}>
					<p>
						Las condiciones macroeconómicas actuales presentan, tanto una gran oportunidad para el comercio
						internacional, como así también un gran desafío. Cada vez más, las empresas argentinas comienzan
						a salir al mundo a comprar y vender bienes y servicios en el mercado global, lo que acarrea un
						riesgo intrínseco en términos de eficiencia en los costos y en el crecimiento de los ingresos.
						En este contexto, la gestión de las exposiciones cambiarias, que siempre ha sido una tarea
						compleja, se vuelve un factor clave para cualquier empresa, sin importar su tamaño, sector o
						grado de expertise. Por eso, Cambio Posadas es la primera entidad cambiaria en contar con una
						División 100% focalizada en el mercado corporativo y la primera en crear un equipo de desarrollo
						comercial segmentado por sector de actividad. Sin moverse de su oficina y sin la necesidad de
						incurrir en costos ni tomar riesgos innecesarios en el transporte físico de los fondos.
					</p>
				</div>
			</div>
			<div className={styles.slider}>
				<ul>
					<li>
						<img src={carousel1} alt='Cambio Posadas' />
					</li>
					<li>
						<img src={carousel2} alt='Cambio Posadas Pizarra' />
					</li>
					<li>
						<img src={carousel3} alt='Euros billetes' />
					</li>
				</ul>
			</div>
		</section>
	);
};

export default Empresas;
