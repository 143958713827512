import React, { Component } from 'react';
import styles from './Home.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';


export default class Home extends Component {
	render() {
		return (
			<section className={styles.section} ref={this.props.homeRef}>
				<div className={styles.home}>
					<div className={`${styles.home__container} ${styles.home__container__text} `}>
						<h1 className={`${styles.home__container__header} ${main.text__main_heading}`}>
							El mejor Cambio del Mercado
						</h1>
						<h3 className={`${styles.home__container__leyend} ${main.text__subtitle}`}>
							Encontrá el mejor tipo de cambio sin moverte de tu casa, negocio o empresa con la mayor
							seguridad, sin perder tiempo y al mejor precio.
						</h3>
					</div>
					<div className={`${styles.home__container} ${styles.home__container__modal}`}>
					
					</div>
				</div>
			</section>
		);
	}
}
