import React, {useState} from 'react';
import '../ProfileForm/ProfileForm.css';
import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import {Monedas} from '../../../Helpers/GlobalVariables';
import {PostHook, GetHook} from '../../../Helpers/Services';
import Cookies from 'js-cookie';
import GeneralModal from '../../Modals/GeneralModal/GeneralModal';


const CreateAlertForm = (props) => {

    const [newAlert, setNewAlert] = useState({});
    const [isAvailable, setIsAvailable] = useState(false);
    const [createModal, setCreateModal] = useState(false);

    const handleChangeAlerts = (e) => {
        newAlert[e.name] = e.value;
        setNewAlert(newAlert);
        if(!!newAlert.monedas && !!newAlert.compraoventa && !!newAlert.monto) {
            setIsAvailable(true);
        } else {
            setIsAvailable(false);
        };
    }

    const submitCreateAlarm = () => {
        let formData = new FormData();
        formData.append('currencyId', newAlert.monedas);
        formData.append('isBid', (newAlert.compraoventa === 'Compra') ? true : false);
        formData.append('value', newAlert.monto);
        PostHook(
            'https://api.cambioposadas.com.ar/public/alert',
            formData,
            {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')}
        ).then(() => {
            setCreateModal(true);
            return GetHook('https://api.cambioposadas.com.ar/public/alert', {'Authorization' : 'Bearer ' + Cookies.get('access-Token-Cambio-Posadas')})
            .then((data) => {
                props.handleAlertChanges(data);
            });
        });
    }


    return (
        <div>
            {
                createModal && 
                    <GeneralModal
                        onClose={()=>setCreateModal(false)}
                        title={'Alerta Creada'}
                        subtitle={'Su alerta fue creada exitosamente!'}
                        buttonDialog={'Continuar'}
                        exitModal={()=>setCreateModal(false)}
                        type='success'

                    />
            }
            <div className='formBox' style={{paddingLeft:0}}>
                <div className='inputSection AlertSection'>
                    <SelectInput  label='' name='monedas' selectOptions={Monedas} value={newAlert.monedas} defaultValue='Seleccionar Moneda' handleChange={handleChangeAlerts}/>
                    <SelectInput  label='' name='compraoventa' value={newAlert.compraoventa} selectOptions={['Compra', 'Venta']} defaultValue='Compra o Venta?' handleChange={handleChangeAlerts}/>
                    <TextInput label={''} name={'monto'} value={newAlert.monto} placeholder={'Ej: 50.00'} handleChange={handleChangeAlerts}/>
                </div>
                <div className='AlertbuttonContainer'>
                    <div className={(!isAvailable) ? 'disabledButton' : ''} onClick={submitCreateAlarm}>Crear</div>
                </div>
            </div>
        </div>
    );
}

export default CreateAlertForm;