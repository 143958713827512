import React from 'react';
import styles from './HowDoesItWork.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import stepOne from '../../../Assets/images/1.png';
import stepTwo from '../../../Assets/images/2.png';
import HowDoesItWorkCard from '../../../Components/Abstracts/Cards/HowDoesItWorkCard/HowDoesItWorkCard';
import cardImageOne from '../../../Assets/images/seleccionar.svg';
import cardImageTwo from '../../../Assets/images/elegi.svg';
import cardImageThree from '../../../Assets/images/retira.svg';
import cardImageFourth from '../../../Assets/images/toque.png';

export const HowDoesItWork = (props) => {
	return (
		<section className={styles.section} ref={props.howDoesItWorkRef}>
			<div className={`${styles.text__container} ${main.u__margin_top_medium_1} `}>
				<h2 className={main.text__secondary_heading_1}>¿Cómo funciona Cambio Posadas?</h2>
				<h3 className={`${main.text__subtitle} ${main.u__margin_top_small_1}`}>
					Para operar a través de la app:
				</h3>
				<div className={`${styles.steps__container} ${main.u__margin_top_small_3} ${main.u__after_line_1}`}>
					<div className={styles.step}>
						<img className={styles.step__image} src={stepOne} alt='step number' />
						<p className={styles.step__text}>Descargá la Aplicación en App Store o Google Play</p>
					</div>
					<div className={styles.step}>
						<img className={styles.step__image} src={stepTwo} alt='step number' />
						<p className={styles.step__text}>
							Completá algunos datos y validaremos personalmente tu perfil.
						</p>
					</div>
				</div>
			</div>
			<div className={`${styles.cards__container} ${main.u__margin_top_medium_1}`}>
				<HowDoesItWorkCard
					image={cardImageOne}
					header='seleccioná tu moneda'
					text=' Más de 50 monedas del mundo para elegir. No importa a donde vayas o donde hayas estado, recuperá tus pesos en Cambio Posadas al mejor precio. '
				/>
				<HowDoesItWorkCard
					image={cardImageTwo}
					header='elegí la modalidad'
					text='Elegí la sucursal mas conveniente, seleccioná delivery si querés que vayamos nosotros u optá por todos los medios electrónicos disponibles para que te liquidemos la operación que desees realizar.'
				/>
				<HowDoesItWorkCard
					image={cardImageThree}
					header='retirá o recibí tu cambio'
					text='Elegí la modalidad que más te convenga para hacerte de tu cambio. En cualquiera de nuestras sucursales, a tu casa, negocio o empresa o desde el home banking de tu banco.'
				/>
			</div>
			<div
				className={`${styles.text__container} ${main.u__margin_top_medium_1} ${main.u__after_line_2}`}
				ref={props.reconnRef}
			>
				<h2 className={main.text__secondary_heading_1}>Reconocé si tu Billete es Falso</h2>
				<p className={`${main.text__paragraph_2} ${main.u__margin_top_small_1} ${main.u__text_align_center}`}>
					Con Cambio Posadas tenés toda la información y la posibilidad de operar desde tu smartphone cuando
					quieras, estés donde estés.
				</p>
				<p className={`${main.text__paragraph_2} ${main.u__margin_top_small_1} ${main.u__text_align_center}`}>
					Somos la única entidad del país que opera más de 30 monedas del mundo, a precios competitivos y con
					un stock permanente en más de 20 monedas de todo el mundo.
				</p>
			</div>
			<div className={`${styles.cards__container} ${main.u__margin_top_medium_1}`}>
				<HowDoesItWorkCard
					image={cardImageFourth}
					header='toque el papel'
					text='Pase sus dedos por el billete. Deberá sentir que es algo áspero al tacto como resultado del proceso de impresión y la composición única del papel.'
				/>
				<HowDoesItWorkCard
					image={cardImageFourth}
					header='incline el billete'
					text='Incline el billete para ver cómo la tinta cambia de color en los números y/o bandas holográficas.'
				/>
				<HowDoesItWorkCard
					image={cardImageFourth}
					header='mire a contraluz'
					text='Sostenga el billete a contraluz para comprobar que la marca de agua y el hilo de seguridad se ven desde ambas caras del billete.'
				/>
			</div>
		</section>
	);
};
