import React, {useState, useEffect} from 'react';
import './ProfileForm';

const TextInput = (props) => {
    const [inputValue, setiInputValue] = useState(props.value);

    useEffect(() => {
        setiInputValue(props.value);
    }, [props.value]);

    // REFACTOR => VALIDATIONS GO HERE

    let inputIcon;
    let styleInput;
    if (props.isEditable !== 'disabled') {
        if(inputValue) {
            inputIcon = <div className='validationSuccessIcon'>✓</div>;
            styleInput = 'textSuccess'
        } else {
            inputIcon = <div className='validationDeniedIcon'>X</div>;
            styleInput = 'textDenied'
        }
    }

    return (
        <div className='inputWrapper'>
            <div>
                <label className='labelBoxElement'>{props.label}</label>
            </div>
            <div className='textInputContainer'>
                <input className={`textInput ${styleInput}`} name={props.name} disabled={props.isEditable} placeholder={props.placeholder} value={inputValue || ''} onChange={e => {
                    setiInputValue(e.target.value)
                    props.handleChange(e.target)
                }}/>
                {inputIcon}
            </div>
        </div>
    );
}

export default TextInput;