import React, {useEffect} from 'react';
import styles from './Envio.module.scss';
import StepHeader from '../FormularioHeader';
import FormularioCard from '../FormularioCard';

const Envio = (props) => {

    useEffect(() => {
        props.isCompleted(false);
        if(props.direccion && props.fecha && props.depto && props.horario) {
            props.isCompleted(true);
        }
    }, [props.direccion, props.fecha, props.depto, props.horario])

	return (
        <StepHeader title={props.title} step={props.step}>
            <FormularioCard titleCard={props.titleCard}>
                <div className={styles.inputContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="direccion" onChange={(e) => props.handleCompletedDireccion(e.target.value)}>Dirección*
                            <input type="text" id="direccion" name="direccion" value={props.direccion}/>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="fecha" onChange={(e) => props.handleCompletedFecha(e.target.value)}>Fecha*
                            <input type="text" id="fecha" name="fecha" value={props.fecha}/>
                        </label>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="depto" onChange={(e) => props.handleCompletedDepto(e.target.value)}>Depto*
                            <input type="text" id="depto" name="depto" value={props.depto}/>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemTitle} htmlFor="horario" onChange={(e) => props.handleCompletedHorario(e.target.value)}>Rango Horario*
                            <input type="text" id="horario" name="horario" value={props.horario} />
                        </label>
                    </div>
                </div>
                <div className={styles.disclaimer}>
                    <p>*Éstos son los datos que necesitas para realizar el pago. Al confirmar la transacción, te enviaremos un mail con los mismos para que puedas volver a verlos en caso de necesitarlos.</p>
                </div>
            </FormularioCard>
        </StepHeader>
	);
};

export default Envio;