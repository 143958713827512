const Provincias = [
    'Buenos Aires',
    'CABA',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucumán'
]

const Monedas = [
    'USD',
    'SEK',
    'RUB',
    'CHF',
    'AUD',
    'AED',
    'PEN',
    'CNY',
    'COP',
    'TRY',
    'CAD',
    'INR',
    'MXN',
    'UYU',
    'CLP',
    'GBP',
    'BRL',
    'EUR',
    'JPY'
]

const Residencia = [
    'Argentino',
    'Residente',
    'No Residente',
    'Turista'
]

const EstadoCivil = [
    'Solter@',
    'Casad@',
    'Divorciad@',
    'Viud@'
]

const TipoDeActividad = [
    'Profesional',
    'Jubilado',
    'Ama de casa',
    'Estudiante',
    'Servicios',
    'Comerciante',
    'Empleado',
    'Empleado Público',
    'Independiente'
]


module.exports = {
    Provincias,
    Residencia,
    EstadoCivil,
    TipoDeActividad,
    Monedas
};

