import React from 'react';
import styles from './Cotizaciones.module.scss';
import main from '../../../Abstracts/Main.module.scss';

const Cotizaciones = (props) => {

	return (
        <div>
            {props.cotizaciones.map((data, key)=> {
                return (
                    <div className={`${styles.card} ${main.u__border_radius_1}`} key={key} onClick={() => props.handleChange(data)}>
                        <div className={styles.card__flagBox}>
                            <img className={styles.card__flag} src={`https://resources.cambioposadas.com.ar/flags/${data.moneda}.png`} alt="flag-logo"/>
                            <p className={styles.card__flagName}>{data.name}</p>
                        </div>
                        <div className={styles.card__buySellBox}>
                            <p className={styles.card__buySellPrice}>$ {data.compra}</p>
                            <span className={styles.card__buySell}>Compra</span>
                        </div>
                        <div className={styles.card__buySellBox}>
                            <p className={styles.card__buySellPrice}>$ {data.venta}</p>
                            <span className={styles.card__buySell}>Venta</span>
                        </div>
                    </div>
                )
            })}
        </div>
	);
};

export default Cotizaciones;