import React from 'react';
import styles from './Appraise.module.scss';
import main from '../../../Components/Abstracts/Main.module.scss';
import Card from '../../../Components/Abstracts/Cards/AppraiceCard/_appraiceCard';

export const Appraise = (props) => {
	return (
		<div className={styles.section} ref={props.appraiseRef}>
			<div className={`${main.u__margin_top_medium_1} ${main.u__after_line_1}`}>
				<h2 className={main.text__secondary_heading_1}>Principales Cotizaciones</h2>
				<h3 className={`${main.text__subtitle} ${main.u__margin_top_small_1}`}>
					Segui las principales cotizaciones en tiempo real.
				</h3>
			</div>
			<div className={`${styles.card__container} ${main.u__margin_top_medium_1}`}>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
				<Card
					flag='https://resources.cambioposadas.com.ar/flags/USD.png'
					name='USA'
					buyPrice='59.00'
					sellPrice='60.40'
				/>
			</div>
		</div>
	);
};

export default Appraise;
