

import React from 'react';
import './LoaderSpinner.css';

const LoaderSpinner = () => {

    return (
        <div className="noShowLoading-container">
            <div className="noShowLoading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div> 
    );
}

export default LoaderSpinner;