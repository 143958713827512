import React from 'react'
import styles from './HowDoesItWorkCard.module.scss'
import main from '../../../Abstracts/Main.module.scss'

export const HowDoesItWorkCard = (props) => {
    return (
        <div className={`${styles.card} ${main.u__scale_up_1}`}>
            <img className={styles.card__image} src={props.image} alt="card Icon"/>
            <h2 className={styles.card__header}>{props.header}</h2>
            <p className={styles.card__text}>{props.text}</p>
        </div>
    )
}

export default HowDoesItWorkCard;